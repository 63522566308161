/* eslint-disable */


import { useDebounceCallback } from "@react-hook/debounce";
import Vsm from "@vsm/vsm";
import "@vsm/vsm/dist/vsm.css";
import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  MouseEvent,
  useCallback,
  useLayoutEffect,
} from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { tmapInterface } from "../../libs/tmap-interface";

import { globalVars } from "../../libs/global-vars";
import { utils } from "../../libs/utils";

// Mok APi
//import { tmapApi } from '../../libs/tmap-api';

// 헤더
import { Header } from "../../components/Layout";
import { HandleBrowserBackButton } from "../../components/Layout";

import { appManager } from "../../managers";

// Util
import _, { isEmpty, isBoolean } from "lodash-es";
import { get } from "lodash-es";

// AxioS
import axios, { AxiosError, AxiosResponse } from "axios";
import Const from "../../store/ev";
import { appActions, store } from "../../store";
import { AccessKey } from "../../components/DebugTools/Panel/Items";
import { modal } from "../../components/GlobalModal";
import { setInited } from "../../routeGuards";

import {
  CenterPointerControl,
  CustomMarker,
  DefaultMarker,
  FullScreenControl,
  getCurrentPosition,
  HtmlElementControl,
  Map,
  //MapBoundsPositionCompatible,
  MapControlDock,
  MapEventListener,
  MapOptions,
  //MapPosition,
  MapPositionCompatible,
  MousePointControl,
  NavigationControl,
  ScaleBarControl,
} from "@vsm/react-vsm";

import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import Sheet from "react-modal-sheet";

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getUser, postUser } from "../../apis/user";
import { getProgress } from "../../apis/progress";
import { postMomenty } from "../../apis/momenty";
import { getAuthStatus, getChargeStatus, getUserStatus } from "../../apis/status";
import { getTopTmap } from "../../apis/toptmap";
import { getPayMeans } from "../../apis/paymeans";
import { getPopup, patchPopup } from "../../apis/popup";
import { getDelivery, getDeliveryWeb } from "../../apis/delivery";
import { retrieveCardInfo } from "../../libs/commonFunction";

//롤링 팝업
import Slider from "react-slick";

import { Popover } from 'react-tiny-popover';

import Lottie from "lottie-react";
import QrAnimation from "./qr_icon.json";
import { datadogRum } from '../../libs/datadog'
import { getCpos } from "../../apis/cpo";
import { getCarInfo } from "../../apis/pnc";
import { getBanner } from "../../apis/banner";

var modeCheckLoop: any = null; // 현재위치 버튼 모드 체크 루프

let userData: any = {}; // 회원정보조회(단건)
let cardInfo: any = {}; // 카드정보
let timer: Nullable<number> = null; // 타이머
let currentPositionLon: any = {}; // 현재위치 or 지도중심좌표 Lon
let currentPositionLat: any = {}; // 현재위치 or 지도중심좌표 Lat
let currentPointLon: any = "0.0"; // 지도상 현재위치 포인터 Lon
let currentPointLat: any = "0.0"; // 지도상 현재위치 포인터 Lat
let dragCount = 0; // 지도 drag 시 count

// let northLon = 0;
// let northLat = 0;
// let southLon = 0;
// let southLat = 0;

function Main(props: RouteComponentProps<{ id: string }>) {
  datadogRum.getInternalContext();

  useEffect(() => { // 메인 진입 로그박스
    mixpanel("evcharge:/evcharge/main");
    logbox("/evcharge/main", "");
  }, [])

  const [popupList, setPopupList] = useState<any>([]) // 팝업 리스트

  useEffect(() => {
    getPopup()
      .then(function (response: any) {
        var infoList = response.infoList;

        if (infoList.filter(x => x.unShowYn === false).length > 0) {
          mixpanel("evcharge:/evcharge/main:view.evchargeintro_popup");
          logbox("/evcharge/main", "view.evchargeintro_popup");
          setPopupList(infoList.filter(x => x.unShowYn === false && x.popupSeq).sort((a, b) => (a.popupSeq - b.popupSeq)));
          setSlidePopVisible(true);
        }
      })
  }, [])

  const [mainBannerList, setMainBannerList] = useState<any>([]) // 팝업 리스트

  useEffect(() => {
    getBanner()
    .then((response: any) => {
      if (response.result) {
        const filterMain = response.list.filter((item) => item.targetPage === "MAIN").sort((a, b) => (a.bannerSeq - b.bannerSeq));

        setMainBannerList(filterMain);

        setInited(true);
      }
    })
    .catch((error: any) => {
      console.log(error)
      setInited(true);
    })
  }, [])

  useEffect(() => {
    getCarInfo()
      .then((response: any) => {
        setCarInfo(response);
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [])

  function getSessionId(value: string) {
    store.dispatch(appActions.setSessionId(value));
  }

  var defaultData: any = {
    result: "",
    station_name: "",
  };

  useEffect(() => {
    document.body.style.cssText = `
      overflow: hidden;`
      ;
    return () => {
      document.body.style.cssText = '';
    };
  }, []);

  const [cpoList, setCpoList] = useState([]) as any;

  const [filterSpeedData, setFilterSpeedData] = useState([]) as any; // 3번째 필터 항목 리스트

  const [filterTypeData, setFilterTypeData] = useState([]) as any; // 4번째 필터 항목 리스트

  const [filterOperatorData, setFilterOperatorData] = useState([]) as any; // 5번째 필터 항목 리스트

  //console.info(`gsLocation: ${JSON.stringify(gsLocation)}`);
  const [currentStatus, setCurrentStatus] = useState(true); // 진입 후 처음 상태 체크용
  const [currentPositionStatus, setCurrentPositionStatus] = useState(false); // 현재위치 좌표 리턴 여부

  // function getLocation() {
  //   //현재위치 호출 및 position에 저장
  //   navigator.geolocation.getCurrentPosition(
  //     function (currentPosition) {
  //       // if (
  //       //   position.center[0] === currentPosition.coords.longitude &&
  //       //   position.center[1] === currentPosition.coords.latitude
  //       // ) {
  //       //   setPosition({
  //       //     center: [
  //       //       currentPosition.coords.longitude + 0.00001,
  //       //       currentPosition.coords.latitude + 0.00001,
  //       //     ], //127.0, 37.5 126.9556914, 37.3834682
  //       //     zoom: 13,
  //       //     bearing: 0,
  //       //     pitch: 0,
  //       //   });
  //       // } else {
  //       //   setPosition({
  //       //     center: [
  //       //       currentPosition.coords.longitude,
  //       //       currentPosition.coords.latitude,
  //       //     ], //127.0, 37.5 126.9556914, 37.3834682
  //       //     zoom: 13,
  //       //     bearing: 0,
  //       //     pitch: 0,
  //       //   });
  //       // }

  //       mapRef.current?.getCamera().flyTo({ center: [currentPosition.coords.longitude, currentPosition.coords.latitude] }, { duration: 1000 })

  //       currentPositionLon = currentPosition.coords.longitude;
  //       currentPositionLat = currentPosition.coords.latitude;
  //       currentPointLon = currentPosition.coords.longitude;
  //       currentPointLat = currentPosition.coords.latitude;

  //       setCurrentStatus(true);

  //       //console.log(position.center[0]);
  //       //console.log(position.center[1]);

  //       //console.log(currentPosition.coords.longitude+', '+currentPosition.coords.latitude)
  //     },
  //     function (error) {
  //       console.error(error);
  //     },
  //     {
  //       enableHighAccuracy: false,
  //       maximumAge: 0,
  //       timeout: Infinity,
  //     }
  //   );
  // }



  const EvDeliveryStatus = () => { // 충전배달 가능 체크
    getDelivery(currentPositionLat, currentPositionLon)
      .then(function (response: any) {
        if (response.data.result) {
          setEvDeliveryButton(true);
        } else {
          setEvDeliveryButton(false);
        }
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };

  const goEvDelivery = () => { // 충전배달 화면 랜딩
    mixpanel("evcharge:/evcharge/main:tap.chargedelivery");
    logbox("/evcharge/main", "tap.chargedelivery");

    if (userData.evDeliveryYn) {
      getDeliveryWeb(currentPositionLat, currentPositionLon)
        .then((response: any) => {
          const objectHtml = document.createElement('div');
          objectHtml.innerHTML = response;
          // console.log(objectHtml.querySelector('form')?.getAttribute('action'));

          tmapInterface.tChargeDelivery(objectHtml.querySelector('form')?.getAttribute('action') as string);
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });
    } else {
      props.history.replace("/evDelivery");
    }

  };

  const goPayInfo = () => { // 결제수단 화면
    mixpanel("evcharge:/evcharge/main:tap.paymethod");
    logbox("/evcharge/main", "tap.paymethod");

    //근처 충전소 리스트 페이지 이동
    props.history.replace("/payMethod");
  };

  useEffect(() => {
    dragCount = 0;
    // getLocation();
    tmapInterface.getCurrentPosition(currentPosition);

    setFilterTmapValue(store.getState().app.tmapFilterValue);
    setFilterPncValue(store.getState().app.pncFilterValue);
    setFilterPublicValue(store.getState().app.publicFilterValue);

    const speedData = store.getState().app.speedFilterFirstValue // 필터 데이터 유지
    setFilterSpeedData(speedData);
    const speedName = speedData.filter((item) => item.status !== "N");

    setFilterSpeedName(
      speedName.length === 0
        ?
        "충전속도"
        :
        speedName.length > 1
          ?
          speedName[0].key + " 외" + (speedName.length - 1)
          :
          speedName[0].key
    );

    const typeData = store.getState().app.typeFilterFirstValue
    setFilterTypeData(typeData);
    const typeName = typeData.filter((item) => item.status !== "N");

    setFilterTypeName(
      typeName.length === 0
        ?
        "충전타입"
        :
        typeName.length > 1
          ?
          typeName[0].key + " 외" + (typeName.length - 1)
          :
          typeName[0].key
    );

    if (!store.getState().app.operatorFilterFirstValue) {
      getCpos()
        .then(function (response: any) {
          const cpoCode = response.cpo.map((item) => item.cpoCode)
          setCpoList(cpoCode);

          const operatorData = response.cpo.map(item => ({
            ...item, "status": "N", "extraStatus": "N"
          }))

          store.dispatch(appActions.setOperatorFilterFirstValue(operatorData));
          filterOperatorResult(operatorData);
        })

        .catch(function (error: any) {
          console.log(error);
        })
    } else {
      const operatorData: any = store.getState().app.operatorFilterFirstValue;
      const cpoCode = operatorData.map((item) => item.cpoCode);

      setCpoList(cpoCode);

      filterOperatorResult(operatorData);
    }

    setFilterAvailableValue(store.getState().app.availableFilterValue);
  }, []);

  const filterOperatorResult = (data) => {
    setFilterOperatorData(data);
    const operatorName = data.filter((item) => item.status !== "N");

    setFilterOperatorName(
      operatorName.length === 0
        ?
        "운영기관"
        :
        operatorName.length > 1
          ?
          operatorName[0].cpoName + " 외" + (operatorName.length - 1)
          :
          operatorName[0].cpoName
    );
  }

  function currentPosition(latitude: string, longitude: string) {

    if (globalVars.isIOS) { //session_id 설정
      if (globalVars.appVersion.length === 6 && Number(globalVars.appVersion.substring(0, 3)) >= 9.1) {
        tmapInterface.getIosSessionId(getSessionId); // session_id 값 저장
      } else if (globalVars.appVersion.length === 5 && Number(globalVars.appVersion.substring(0, 3)) >= 9.9) {
        tmapInterface.getIosSessionId(getSessionId); // session_id 값 저장
      }
    } else if (globalVars.isAndroid) {
      if (globalVars.appVersion.length === 6 && Number(globalVars.appVersion.substring(0, 3)) >= 9.1) {
        store.dispatch(appActions.setSessionId(tmapInterface.getAosSessionId())); // session_id 값 저장
      } else if (globalVars.appVersion.length === 5 && Number(globalVars.appVersion.substring(0, 3)) >= 9.9) {
        store.dispatch(appActions.setSessionId(tmapInterface.getAosSessionId())); // session_id 값 저장
      }
    }

    if (Const.DBG_OK) {
      alert("QrCode" + status + " " + JSON.stringify(latitude) + JSON.stringify(longitude));
    }

    //qr코드가 '' 빈값일때 qr인증실패로 이동
    currentPositionLat = Number(latitude);
    currentPositionLon = Number(longitude);
    currentPointLat = Number(latitude);
    currentPointLon = Number(longitude);

    setCurrentPositionStatus(true);

    if (position.center[0] === Number(longitude) && position.center[1] === Number(latitude)) {
      setPosition({
        center: [Number(longitude) + 0.00001, Number(latitude) + 0.00001],
        zoom: 15,
        bearing: 0,
        pitch: 0
      });
    } else {
      setPosition({
        center: [Number(longitude), Number(latitude)],
        zoom: 15,
        bearing: 0,
        pitch: 0
      });
    }

  }

  const [currentButton, setCurrentButton] = useState(false); // 현재 위치 버튼 클릭
  const [currentButtonPosition, setCurrentButtonPosition] = useState(false); // 현재 위치 버튼 클릭

  function currentPositionRefresh(latitude: string, longitude: string) {
    if (Const.DBG_OK) {
      alert("QrCode" + status + " " + JSON.stringify(latitude) + JSON.stringify(longitude));
    }

    // setDragging(true);

    //qr코드가 '' 빈값일때 qr인증실패로 이동
    setCurrentButton(true);
    currentPositionLat = Number(latitude);
    currentPositionLon = Number(longitude);
    currentPointLon = Number(longitude);
    currentPointLat = Number(latitude);

    setCurrentButtonPosition(true);

    // if(position.center[0] === Number(longitude) && position.center[1] === Number(latitude)){
    //   setPosition({
    //       center: [Number(longitude)+0.00001, Number(latitude)+0.00001], //127.0, 37.5  126.9556914, 37.3834682
    //       zoom: 13,
    //       bearing: 0,
    //       pitch: 0
    //     });
    // } else {
    //     setPosition({
    //         center: [Number(longitude), Number(latitude)], //127.0, 37.5  126.9556914, 37.3834682
    //         zoom: 13,
    //         bearing: 0,
    //         pitch: 0
    //       });
    // }

    mapRef.current?.getCamera().flyTo({ center: [Number(longitude), Number(latitude)] }, { duration: 1000 })

    getTopTmap(Number(latitude), Number(longitude))
      .then(function (response: any) {

        const result = response;
        // setEvDeliveryButton(false);

        setRefreshName(result.addressInfo.gu_gun + ' ' + result.addressInfo.legalDong);
        setRefreshButton(true);
        // setRefreshCurrent(true);

      })
      .catch(function (error: any) {
        if (Const.DBG_OK) {
          alert("충전소 정보 조회실패");
        }
        setRefreshButton(false);
        //alert('error - ' + error);
      });
  }

  function currentPositionSecondMode(latitude: string, longitude: string) {

    // currentPositionLat = Number(latitude)
    // currentPositionLon = Number(longitude)
    // alert(Number(longitude)+" / "+Number(latitude));

    // setDragging(true);

    currentPositionLat = Number(latitude);
    currentPositionLon = Number(longitude);
    currentPointLon = Number(longitude);
    currentPointLat = Number(latitude);

    // if(position.center[0] === Number(longitude) && position.center[1] === Number(latitude)){
    //   currentPointLon = Number(longitude)+0.00001;
    //   currentPointLat = Number(latitude)+0.00001;
    //   setPosition({
    //       center: [Number(longitude)+0.00001, Number(latitude)+0.00001], //127.0, 37.5  126.9556914, 37.3834682
    //       zoom: 13,
    //       bearing: 0,
    //       pitch: 0
    //     });
    // } else {
    //   currentPointLon = Number(longitude);
    //   currentPointLat = Number(latitude);
    //     setPosition({
    //         center: [Number(longitude), Number(latitude)], //127.0, 37.5  126.9556914, 37.3834682
    //         zoom: 13,
    //         bearing: 0,
    //         pitch: 0
    //       });
    // }
    mapRef.current?.getCamera().panTo({ lng: Number(longitude), lat: Number(latitude) }, { duration: 500 })

    var lon = Number(longitude);
    var lat = Number(latitude);

    getTopTmap(Number(latitude), Number(longitude))
      .then(function (response: any) {

        const result = response;
        // setEvDeliveryButton(false);

        setRefreshName(result.addressInfo.gu_gun + ' ' + result.addressInfo.legalDong);
        setRefreshButton(true);
        // setRefreshCurrent(true);

      })
      .catch(function (error: any) {
        if (Const.DBG_OK) {
          alert("충전소 정보 조회실패");
        }
        setRefreshButton(false);

        //alert('error - ' + error);
      });
  }

  useEffect(() => {
    //alert(currentPositionLon)
    if (currentPositionLon?.toString().substring(0, 1) === '1' && currentStatus === true && currentPositionStatus) {
      getFirstStationInfo(); //현재 위치값은 처음 한번만 호출
      EvDeliveryStatus(); //충전배달 호출 임시 주석 처리
      setCurrentStatus(false); // 처음 체크
    }

  }, [currentPositionLon, currentPositionStatus]);

  //현재위치 버튼 모드
  const [currentPositionMode, setCurrentPositionMode] = useState("1");

  useEffect(() => { // 현재위치 버튼 모드
    if (currentPositionMode === "1") {
      clearInterval(modeCheckLoop);
    } else if (currentPositionMode === "2") {
      if (modeCheckLoop != null) {
        clearInterval(modeCheckLoop);
      }
      modeCheckLoop = setInterval(() => {
        tmapInterface.getCurrentPosition(currentPositionSecondMode);
      }, 2000);
    }

  }, [currentPositionMode]);

  const mapRef = useRef<Vsm.Map>(null); // vsm ref
  const [status, setStatus] = useState(""); // 지도 중심 좌표
  const [onDragPosition, setOnDragPosition] = useState<any>([]); // 지도 이동 중심 좌표
  const [position, setPosition] = useState<MapPositionCompatible>({ //지도 호출 좌표
    center: [0.0, 0.0], //127.0, 37.5  126.9556914, 37.3834682
    zoom: 15,
    bearing: 0,
    pitch: 0,
  });
  const [positionStatus, setPositionStatus] = useState(false);
  const [positionSuccess, setPositionSuccess] = useState(false);

  const [stationInfo, setStationInfo] = useState<any>([]);
  const [focusStationInfo, setFocusStationInfo] = useState<any>([]); // 클릭 포커스된 충전소 정보
  const [chargerInfo, setChargerInfo] = useState<any>([]);

  const [carInfo, setCarInfo] = useState<any>([]);

  const [inited, setInited] = useState(false);
  
  const [userInfo, setUserInfo] = useState(true);
  
  // 팝업
  const [slidePopVisible, setSlidePopVisible] = useState(false); // 슬라이드 팝업

  var settings = { // 슬라이드 style
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // nextArrow: <SampleNextArrow/>,
    // prevArrow: <SamplePrevArrow />,
    className: 'slider_custom',
    // beforeChange: (beforeIndex, afterIndex) => {
    //   setCurrentSlideIndex(afterIndex);
    // },
    appendDots: (dots: any) => (
      <div
        style={{
          width: '100%',
          position: 'fixed',
          bottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    dotsClass: 'dots_custom'
  }

  //결제수단
  const [payType, setPayType] = useState("N"); // 결제수단 : TODO

  //충전배달
  const [evDeliveryButton, setEvDeliveryButton] = useState(false); // 충전배달 버튼
  //지도 동 버튼
  const [refreshButton, setRefreshButton] = useState(false); // 재검색 버튼
  const [refreshName, setRefreshName] = useState(""); // 재검색 버튼 이름
  const [refreshStatus, setRefreshStatus] = useState(true); // 지도 이동으로 재검색 상태 (충전 비활성화)
  const [refreshCurrent, setRefreshCurrent] = useState(true); // 현재위치로 재검색 상태 (충전 활성화)

  // 바텀시트
  const [open, setOpen] = useState(false);
  const [modalSheet, setModalSheet] = useState("");
  const outSection = useRef<any>(null);

  const [isNull, setIsNull] = useState(false);

  // 팝오버
  const [popoverOpen, setPopoverOpen] = useState(true);

  useEffect(() => {
    setPopoverOpen(store.getState().app.popoverOpen);
  }, [])

  //충전 내역
  let temp = "";
  const [displayDate, setDisplayDate] = useState(false);

  const [historyList, setHistoryList] = useState({
    data: [
      {
        idx: "",
        real_end_date: "",
        station_name: "",
        address: "",
        real_use_kwh: "",
        use_point: "",
      },
    ],
    total_data_cnt: 0,
    sum_use_point: "",
    sum_use_time: "",
    sum_use_kwh: "",
    result: "false",
  });

  //const syncPosition = useDebounceCallback(setPosition, 200);

  // const [boundsPosition, setBoundsPosition] =
  //     useState<MapBoundsPositionCompatible>({
  //         bounds: largeBounds,
  //         padding: 100
  //     });

  // const [maxBounds, setMaxBounds] = useState<
  //     Vsm.LngLatBoundsCompatible | undefined
  // >();

  //const [minZoom, setMinZoom] = useState<number>(5);

  const startTime = useMemo(() => performance.now(), []);
  const [firstRenderComplete, setFirstRenderComplete] = useState(false);
  //const [mainBottomSheet, setMainBottomSheet] = useState(true); //바텀시트 내용

  const sheetRef = useRef<BottomSheetRef>(null); // 바텀시트 ref
  const [clickBottomSheetList, setClickBottomSheetList] = useState(false); // 바텀시트 헤더 클릭
  const [stationCount, setStationCount] = useState(0); // 충전소 개수

  const [filterBottomSheet, setFilterBottomSheet] = useState(false); // 필터 시트

  const [filterTitle, setFilterTitle] = useState(""); // 필터 헤더 타이틀

  const [filterApplyClick, setFilterApplyClick] = useState(false); //필터적용 버튼 클릭 상태값

  const [filterTmapValue, setFilterTmapValue] = useState("Y"); // 티맵결제 필터 적용 값

  const [filterPncValue, setFilterPncValue] = useState<any>(""); // 오토차지 필터 적용 값

  const [filterPublicValue, setFilterPublicValue] = useState("public"); // 공용 필터 적용 값

  const [filterSpeed, setFilterSpeed] = useState(false); // 충전속도 필터 시트
  const [filterSpeedName, setFilterSpeedName] = useState("충전속도"); // 충전속도 필터 표출 명
  const [filterSpeedValue, setFilterSpeedValue] = useState(""); // 충전속도 필터 적용 값

  const [filterType, setFilterType] = useState(false); // 충전타입 필터 시트
  const [filterTypeName, setFilterTypeName] = useState("충전타입"); // 충전타입 필터 표출 명
  const [filterTypeValue, setFilterTypeValue] = useState(""); // 충전타입 필터 적용 값

  const [filterOperator, setFilterOperator] = useState(false); // 운영기관 필터 시트
  const [filterOperatorName, setFilterOperatorName] = useState("운영기관"); // 운영기관 필터 표출 명
  const [filterOperatorValue, setFilterOperatorValue] = useState(""); // 운영기관 필터 적용 값

  const [filterAvailableValue, setFilterAvailableValue] = useState("CHARGING_STANDBY"); // 이용가능 필터 적용 값

  const [payCount, setPayCount] = useState(0); //결제수단 조회

  const [stationList, setStationList] = useState([
    //충전소
    {
      lat: "",
      long: "",
    },
  ]);

  useEffect(() => {
    if (payCount > 0) {
      setPayType(store.getState().app.payType);
    }
  }, [payCount]);

  const getPayMethod = () => {
    const promise = retrieveCardInfo();
    const getData = () => {
      promise.then((result) => {
        if (result) {
          setPayCount(payCount + 1)
        }
      });
    };
    getData();
  }

  const checkUserStatus = () => {
    getUserStatus()
      .then(function (response: any) {
        if (response.result) {
          if (response.chargeSessionGuid || response.authSessionGuid) {
            return;
          } else if (response.unpaidGuid) {
            store.dispatch(appActions.setGuid(response.unpaidGuid));
            modal.alert(() => {
              props.history.replace("/nonPay");
            }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");
          }
        }
      })
      .catch(function (error: any) {
        console.log(error);
      })
  }

  //충전소 포커스
  const [focusStation, setFocusStation] = useState(""); // 포커스 된 충전소
  const [focusStationBtn, setFocusStationBtn] = useState(false); // 포커스 된 충전소 버튼
  const [stationSheet, setStationSheet] = useState(false); // 포커스 된 충전소 시트

  //상단 플로팅 포커스
  const [focusSearch, setFocusSearch] = useState(false); // 포커스 된 충전소
  const [focusBackBtn, setFocusBackBtn] = useState(false); // 포커스 된 back
  const [focusDeliBtn, setFocusDeliBtn] = useState(false); // 포커스 충전배달

  //
  const [focusPay, setFocusPay] = useState(false); // 포커스 결제수단
  const [focusCurrent, setFocusCurrent] = useState(false); // 포커스 재검색버튼
  const [focusPncBtn, setFocusPncBtn] = useState(false); // 포커스 pnc버튼
  const [focusLocationBtn, setFocusLocationBtn] = useState(false); // 포커스 현재위치

  //필터 pressed
  const [focusTmap, setFocusTmap] = useState(false); // 티맵결제
  const [focusPnc, setFocusPnc] = useState(false); // 티맵결제
  const [focusPublic, setFocusPublic] = useState(false); // 공용
  const [focusSpeed, setFocusSpeed] = useState(false); // 속도
  const [focusType, setFocusType] = useState(false); // 타입
  const [focusCpo, setFocusCpo] = useState(false); // 운영기관
  const [focusUse, setFocusUse] = useState(false); // 이용가능

  const [pressed, setPressed] = useState({
    focusFilter: false,
    focusReset: false,
    // focusGuid: ""
  })

  //자세히
  const [focusDetail, setFocusDetail] = useState(false); // 
  const [focusDetailList, setFocusDetailList] = useState(false); // 


  //바텀시트
  const [bottomSheet, setBottomSheet] = useState(true); // 바텀시트
  const [contentDrag, setContentDrag] = useState(true);

  const [focusCurrntStatus, setFocusCurrentStatus] = useState(false);
  const [focusCharging, setFocusCharging] = useState(false);

  //탭탭차지 포커스
  const [focusTapBtn, setFocusTapBtn] = useState(false); // 포커스 탭탭차지 버튼

  useEffect(() => {
    // console.log(JSON.stringify(globalVars));
    // alert(window.innerWidth);

    // 타이머 체크
    if (globalVars.connCheckLoop != null) {
      if (Const.DBG_OK) {
        alert("connCheckLoop 살아있음");
      }

      clearInterval(globalVars.connCheckLoop);
    }

    // 0 회원결제카드 조회
    getPayMethod();

    if (Const.DBG_OK) {
      console.log("#1 개발서버 AccessKey 획득 - END", globalVars.accessKey);
      console.log("#1 key : ", globalVars.accessKey);

      // alert("AccessKey 획득 : \n"+globalVars.accessKey);
    }

    // ACCESSKEY 가 있으면
    if (globalVars.accessKey) {
      let accessKey = globalVars.accessKey;

      if (Const.DBG_OK) {
        console.log("#2 AccessKey 검증 및 이상없으면 회원정보 리턴");
      }

      getUser()
        .then(function (response: any) {
          // 기존회원이므로 회원정보 받기
          if (response.result) {
            userData = response;

            if (Const.DBG_OK) {
              alert("사용자정보 " + JSON.stringify(userData));
            }

            mainStart();

          }
        })
        .catch(function (error: any) {
          if (error.response.status === 400 || error.response.data.result === false) {
            // 신규회원등록
            var data = {};

            postUser(data)
              .then(function (response: any) {
                if (Const.DBG_OK) {
                  // console.log('신규회원등록')
                  alert("신규회원등록 " + JSON.stringify(response.data));
                }

                getUser()
                  .then(function (response: any) {
                    userData = response;

                    mainStart();

                    if (Const.DBG_OK) {
                      // console.log('신규회원정보 조회성공')
                      alert("신규회원 " + JSON.stringify(response.data));
                    }
                  })
                  .catch(function (error: any) {
                    //							alert('신규회원정보 조회실패')
                    console.log(error);
                  });
              })
              .catch(function (error: any) {
                console.log(error);
              });
          }
        });
    } else {
      if (Const.DBG_OK) {
        alert("ACCESS KEY 없는경우 - 접속 제한 ()");
        //props.history.replace("/notFound");
      }
      props.history.replace("/notFound");
    }

    setUserInfo(false);
  }, [userInfo]);

  function mainStart() {
    if (Const.DBG_OK) {
      // console.log('mainStart')
      alert("mainStart - userData : " + userData.result);
    }

    // 1.1 회원정보 조회 – DB에 회원키 존재 시
    if (userData.result) {
      if (Const.DBG_OK) {
        alert("사용자 정보 \n" + JSON.stringify(userData));
        alert(
          "유종타입 : " + typeof globalVars.fuelType + " " + globalVars.fuelType
        );
      }

      // 0 회원결제카드 조회
      getPayMethod();
      checkUserStatus();

      //3 TODO : 유종 전기차 아님 (?) or
      //         또는 팝업 다시보지 않기 미체크 시 (ev_check_yn)
      if (Const.DBG_OK) {
        console.log("팝업 다시보지 않기 : ", userData.ev_check_yn);
        //				alert("팝업 다시보지 않기 : " + userData.ev_check_yn);
      }

    }

    function newFunction(evPopCloseYn: string) {
      return evPopCloseYn != "Y";
    }
  }

  function getStationInfo() { // 충전소 조회 (모멘티)
    var lon = currentButtonPosition ? currentPositionLon : status.substring(1, 12);
    var lat = currentButtonPosition ? currentPositionLat : status.substring(14, 24);
    var tmapyn = store.getState().app.tmapFilterValue;
    var pncIncludeYn = store.getState().app.pncFilterValue;
    var publicType = store.getState().app.publicFilterValue;
    var chargerSpeed = store.getState().app.speedFilterValue;
    var chargeType = store.getState().app.typeFilterValue;
    var operator = store.getState().app.operatorFilterValue;
    var chargeStatus = store.getState().app.availableFilterValue;

    var data = {
      lon: lon,
      lat: lat,
      tmapPrivateEvYn: tmapyn,
      pncIncludeYn: pncIncludeYn,
      evPublicType: publicType,
      chargerSpeed: chargerSpeed,
      evChargeType: chargeType,
      operatorId: operator,
      evChargeStatus: chargeStatus,
      radius: "1000"
    };

    postMomenty(data)
      .then(function (response: any) {
        const station = [
          ...response.body.docs
            .map((item, index) => {
              return item?.poi_id === stationInfo.filter((item)=>item?.selected)[0]?.poi_id ? { ...item, selected: true } : { ...item, selected: false };
            })
        ]

        if (response.body.total_count === 0) {
          setIsNull(true);
        } else {
          setIsNull(false);
        }

        const stationFilter = station.filter((item) => item.distance <= 10000)

        setStationInfo(station);
        // console.log(stationFilter?.length)
        setStationCount(stationFilter?.length);
      })
      .catch(function (error: any) {
        if (Const.DBG_OK) {
          alert("충전소 정보 조회실패");
        }
        //alert('error - ' + error);
      });
  }

  const updateZIndex = () => {
    parentMarkerNode.current.forEach((item, index) => {
        item.parentNode.style.zIndex = stationInfo[index]?.selected ? 9 : 0;
    });
  };

  useEffect(() => {
    updateZIndex();
  },[stationInfo])

  // function mapPosition() { //기본 지도 boundsPosition 값 호출
  //   window.setTimeout(() => {
  //     const topLat = mapRef.current?.getCamera().getBounds().northEast.lat;
  //     const bottomLat = mapRef.current?.getCamera().getBounds().southWest.lat;
  //     const topLon = mapRef.current?.getCamera().getBounds().northEast.lng;
  //     const bottomLon = mapRef.current?.getCamera().getBounds().southWest.lng;
  //     if (topLat && bottomLat && topLon && bottomLon) {
  //       northLat = bottomLat + ((topLat - bottomLat) * ((window.innerHeight - 310) / (window.innerHeight - 100)));
  //       southLat = bottomLat + ((topLat - bottomLat) * ((window.innerHeight * 0.45 - 100) / (window.innerHeight - 100)));
  //       northLon = topLon;
  //       southLon = bottomLon;
  //     }
  //   }, 200);
  // }

  function getFirstStationInfo() {
    var lon = currentPositionLon;
    var lat = currentPositionLat;
    var tmapyn = store.getState().app.tmapFilterValue;
    var pncIncludeYn = store.getState().app.pncFilterValue;
    var publicType = store.getState().app.publicFilterValue;
    var chargerSpeed = store.getState().app.speedFilterValue;
    var chargeType = store.getState().app.typeFilterValue;
    var operator = store.getState().app.operatorFilterValue;
    var chargeStatus = store.getState().app.availableFilterValue;

    var data = {
      lon: lon,
      lat: lat,
      tmapPrivateEvYn: tmapyn,
      pncIncludeYn: pncIncludeYn,
      evPublicType: publicType,
      chargerSpeed: chargerSpeed,
      evChargeType: chargeType,
      operatorId: operator,
      evChargeStatus: chargeStatus,
      radius: "1000"
    };

    postMomenty(data)
      .then(function (response: any) {

        // mapPosition();

        setStationInfo(response.body.docs);
        setStationCount(response.body.docs?.length);
        if (response.body.total_count === 0) {
          setIsNull(true);
        } else {
          setIsNull(false);
        }

      })
      .catch(function (error: any) {
        if (Const.DBG_OK) {
          alert("충전소 정보 조회실패");
        }
        setIsNull(true);
      });
  }

  useEffect(() => {
    if (filterApplyClick) {
      getStationInfo();
      setFilterApplyClick(false);
    }
  }, [filterTmapValue, filterPncValue, filterPublicValue, filterSpeedValue, filterTypeValue, filterOperatorValue, filterAvailableValue]);

  const goStationInfo = () => {
    //props.history.replace('/chargerDetail');
    store.dispatch(appActions.setGuid(userData.now_charging_guid));
  };

  const goPoiDetail = (log: string) => {
    if (log === 'poi') {
      mixpanel("evcharge:/evcharge/main:tap.poi");
      logbox("/evcharge/main", "tap.poi");
    } else {
      mixpanel("evcharge:/evcharge/main:tap.detail");
      logbox("/evcharge/main", "tap.detail");
    }

    tmapInterface.startPoiDetailActivity(poiDetail);
  };

  function poiDetail(status: string, address: string) {
    if (Const.DBG_OK) {
      alert("poiDetail" + status + " " + JSON.stringify(address));
    }
  }

  const goStartEasyPayChagingView = (poi: string, lon: string, lat: string) => {

    // logBox.setLocal_time(timeformat)
    // .setAction_id("tap.chargestart");

    // axios.get(logBox.getTlaURIEncode())
    // .then(function (response : any) {
    //   const test = response.data
    // })

    getUserStatus()
      .then(function (response: any) {
        if (response.result) {
          if (response.unpaidGuid) {
            store.dispatch(appActions.setGuid(response.unpaidGuid));
            modal.alert(() => {
              props.history.replace("/nonPay");
            }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");
          } else if (response.activeSessionCount >= 3) {
            mixpanel("evcharge:/evcharge/main:view.allmultiuse_popup");
            logbox("/evcharge/main", "view.allmultiuse_popup");
            modal.alert(() => {
              mixpanel("evcharge:/evcharge/main:tap.allmultiuse_ok");
              logbox("/evcharge/main", "tap.allmultiuse_ok");
            }, "사용 가능한 3건의 충전을 모두 사용중에 있습니다. <br/>충전 종료 후 다시 이용해주세요.");
          } else {
            if (payType == "N") {
              // 결제수단이 없는 경우
              payMethodPop();
            } else {
              props.history.replace(`/tapCharge/${poi}/${lon}/${lat}`);
            }
          }
        }
      })
  };

  const goChargeCurrentStatus = () => {
    mixpanel("evcharge:/evcharge/main:tap.chargestatus");
    logbox("/evcharge/main", "tap.chargestatus");

    getUserStatus()
      .then(function (response: any) {
        if (response.result) {
          if (response.activeSessionCount === 0) {
            mixpanel("evcharge:/evcharge/main:view.nousecharge_popup");
            logbox("/evcharge/main", "view.nousecharge_popup");
            modal.alert(() => {
              mixpanel("evcharge:/evcharge/main:tap.nousecharge_ok");
              logbox("/evcharge/main", "tap.nousecharge_ok");
            }, "사용 중인 충전기가 없습니다.");
          } else if (response.chargeSessionGuid) {
            store.dispatch(appActions.setGuid(response.chargeSessionGuid));

            getAuthStatus(response.chargeSessionGuid)
              .then(function (response2: any) {
                store.dispatch(appActions.setRemoteOffYn(response2.remoteOffYn));
                store.dispatch(appActions.setChargerSpeed(response2.chargerSpeed));

                props.history.push(`/charging/${response.chargeSessionGuid}`);
              })
            // } else if (response.unpaidGuid) {
            //   store.dispatch(appActions.setGuid(response.chargeSessionGuid));

            //   modal.alert(() => {
            //     props.history.replace("/nonPay");
            //   }, "", "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해", "결제를 진행해주세요.");
          } else if (response.authSessionGuid) {
            store.dispatch(appActions.setGuid(response.authSessionGuid));

            getAuthStatus(response.authSessionGuid)
              .then(function (response2: any) {
                store.dispatch(appActions.setRemoteOffYn(response2.remoteOffYn));
                store.dispatch(appActions.setChargerSpeed(response2.chargerSpeed));

                if (response2.authStatus === 1 || response2.authStatus === 2) {
                  store.dispatch(appActions.setQrStatus("1"));
                  props.history.replace("/qrAuth");
                } else if (response2.authStatus === 4 || response2.authStatus === 6) {
                  props.history.push(`/charging/${response.authSessionGuid}`);
                }
              })
          } else {
            mixpanel("evcharge:/evcharge/main:view.nousecharge_popup");
            logbox("/evcharge/main", "view.nousecharge_popup");
            modal.alert(() => {
              mixpanel("evcharge:/evcharge/main:tap.nousecharge_ok");
              logbox("/evcharge/main", "tap.nousecharge_ok");
            }, "사용 중인 충전기가 없습니다.");
          }
        }
      })
    // modal.alert(() => {
    // },"","사용 가능한 3건의 충전을 모두 사용중에 있습니다. <br/>충전 종료 후 다시 이용해주세요.");
  }

  const goStartQrChagingView = (selectCpo?:string) => {
    store.dispatch(appActions.setQrStatus(''));

    mixpanel("evcharge:/evcharge/main:tap.qrscan");
    logbox("/evcharge/main", "tap.qrscan");

    getUserStatus()
      .then(function (response: any) {
        if (response.result) {
          if (response.unpaidGuid) {
            store.dispatch(appActions.setGuid(response.unpaidGuid));
            modal.alert(() => {
              props.history.replace("/nonPay");
            }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");
          } else if (response.activeSessionCount >= 3) {
            mixpanel("evcharge:/evcharge/main:view.allmultiuse_popup");
            logbox("/evcharge/main", "view.allmultiuse_popup");
            modal.alert(() => {
              mixpanel("evcharge:/evcharge/main:tap.allmultiuse_ok");
              logbox("/evcharge/main", "tap.allmultiuse_ok");
            }, "사용 가능한 3건의 충전을 모두 사용중에 있습니다. <br/>충전 종료 후 다시 이용해주세요.");
          } else {
            if (payType == "N") {
              // 결제수단이 없는 경우
              payMethodPop();
            } else {
              if (selectCpo === 'IN') {
                setTimeout(() => {
                  tmapInterface.makeToast(
                    '스파로스 충전기는 커넥터를 연결 후 QR코드를 촬영해주세요.'
                  );
                }, 300);
              }
              popClosed_TESTQR()
            }
          }
        }
      })
  };

  const popClosed_TESTQR = () => {
    // PIN 번호 화면 호출
    tmapInterface.startQrCodeScanActivity(QrCodeScan);
  };

  function selectPin(code: string, status: string) {
    if (code != "0") {
      // alert('selectPin 실패')
      props.history.replace("/main");
    } else {
      // QR 화면 호출
      tmapInterface.startQrCodeScanActivity(QrCodeScan);
    }
  }

  function QrCodeScan(status: string, qrcode: string) {
    if (Const.DBG_OK) {
      alert("QrCode" + status + " " + JSON.stringify(qrcode));
    }
    //qr코드가 '' 빈값일때 qr인증실패로 이동
    if (status === "COMPLETE") {
      if (!qrcode) {
        props.history.replace("/authFail");
      } else {
        store.dispatch(appActions.setQrCode(qrcode));

        props.history.replace("/qrAuth");
      }
    } else if (status === "CANCEL") {
      mixpanel("evcharge:/evcharge/qrscan:tap.back");
      logbox("/evcharge/qrscan", "tap.back");
    }
  }

  const goPayChoiceView = () => {
    tmapInterface.startPaymentActivity(selectCard);
  };

  function selectCard(status: string) {
    if (Const.DBG_OK) {
      alert("selectCard \n " + "status: " + status);
    }

    if (globalVars.isIOS) {
      tmapInterface.getAccessKey(getAccessKey);
    } else {
      globalVars.accessKey = tmapInterface.getAccessKey();

      getPayMethod();

      props.history.replace("/main");
    }
  }

  function getAccessKey(value: string) {
    globalVars.accessKey = value

    getPayMethod();

    props.history.replace("/main");
  }

  const payMethodPop = () => {
    modal.alert(() => {
      popClosed();
    }, "결제 카드 등록 후</br>충전을 진행하실 수 있습니다.");
  };

  const popClosed = () => {
    if (timer) clearTimeout(timer);
    timer = window.setTimeout(() => goPayChoiceView(), 1);
  };

  const popupLink = (popupCode: string, index: number) => {
    mixpanel(`evcharge:/evcharge/main:tap.evchargeintro_detail${index + 1}`);
    logbox("/evcharge/main", `tap.evchargeintro_detail${index + 1}`);

    switch (popupCode) {
      case 'EV_DELIVERY_COVERAGE':
        return props.history.replace("/deliveryPromotion");
      case 'EV_DELIVERY_FREE':
        return props.history.replace("/freeDeliveryPromotion");
      case 'CARD_KB_PLCC':
        return tmapInterface.openBrowser(
          "tmap://promotion?pageid=plcc_202308"
        );
      case 'IN_PROMOTION':
        return props.history.replace("/spharosPromotion");
      case 'KB_PAY':
        return tmapInterface.openBrowser(
          "tmap://communication?pageid=eventDetail&seq=48"
        );
      case 'KB_PAY_2':
        return tmapInterface.openBrowser(
          "tmap://communication?pageid=eventDetail&seq=52"
        );
      case 'KB_PAY_3':
        return tmapInterface.openBrowser(
          "tmap://communication?pageid=eventDetail&seq=59"
        );
      case 'PL_PROMOTION':
        return props.history.replace("/plugLinkPromotion");
      default:
        break;
    }
  }

  const popupState = () => { //팝업 다시보지 않기
    mixpanel("evcharge:/evcharge/main:tap.evchargeintro_forcequit");
    logbox("/evcharge/main", "tap.evchargeintro_forcequit");

    const popupUnShowIdxs = popupList.map((item, index) => item.popupUnShowIdx)

    var data = {
      popupUnShowIdxs: popupUnShowIdxs
    }

    patchPopup(data)
      .then(function (response: any) {
        setSlidePopVisible(false);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };

  const goBlog = () => {
    tmapInterface.openBrowser(
      "https://blog.naver.com/tmapmobility/222596785240"
    );
  };

  const closePopup = () => {
    mixpanel("evcharge:/evcharge/main:tap.evchargeintro_quit");
    logbox("/evcharge/main", "tap.evchargeintro_quit");

    setSlidePopVisible(false);
  };

  const goSearch = () => {
    mixpanel("evcharge:/evcharge/main:tap.search");
    logbox("/evcharge/main", "tap.search");

    props.history.push("/poiSearch");
  };

  const goBack = () => {
    mixpanel("evcharge:/evcharge/main:tap.back");
    logbox("/evcharge/main", "tap.back");

    if (stationSheet) {
      onClickCustomMarker("");
      setStationSheet(false);
    } else {
      tmapInterface.onBackKeyPressed();
    }
  }

  //1번째 필터 적용
  const filterTmapStatus = () => {
    mixpanel("evcharge:/evcharge/main:tap.pay");
    logbox("/evcharge/main", "tap.pay");

    if (refreshCurrent) {
      setRefreshStatus(true);
    } else {
      setRefreshStatus(false);
    }

    setFilterApplyClick(true);

    if (filterTmapValue === "") {
      setFilterTmapValue("Y");
      store.dispatch(appActions.setTmapFilterValue("Y"));
    } else if (filterTmapValue === "Y") {
      setFilterTmapValue("");
      store.dispatch(appActions.setTmapFilterValue(""));
    }
  };

  const filterPncStatus = () => {
    mixpanel("evcharge:/evcharge/main:tap.autochargestation");
    logbox("/evcharge/main", "tap.autochargestation");

    if (refreshCurrent) {
      setRefreshStatus(true);
    } else {
      setRefreshStatus(false);
    }

    setFilterApplyClick(true);

    if (filterPncValue === "") {
      setFilterPncValue(true);
      store.dispatch(appActions.setPncFilterValue(true));
    } else if (filterPncValue) {
      setFilterPncValue("");
      store.dispatch(appActions.setPncFilterValue(""));
    }
  };

  // 2번쨰 필터 적용
  const filterPublicStatus = () => {
    mixpanel("evcharge:/evcharge/main:tap.open");
    logbox("/evcharge/main", "tap.open");

    if (refreshCurrent) {
      setRefreshStatus(true);
    } else {
      setRefreshStatus(false);
    }

    setFilterApplyClick(true);

    if (filterPublicValue === "public") {
      setFilterPublicValue("");
      store.dispatch(appActions.setPublicFilterValue(""));
    } else if (filterPublicValue === "") {
      setFilterPublicValue("public");
      store.dispatch(appActions.setPublicFilterValue("public"));
    }
  };

  // 3번째 필터 적용
  const filterSpeedStatus = () => {
    const obj = filterSpeedData.map(item => item.status !== item.extraStatus ? { ...item, extraStatus: item.status } : item);
    setFilterSpeedData(obj);
    // filterSpeedData.forEach(element => {
    //   element.extraStatus = element.status
    // })
    mixpanel("evcharge:/evcharge/main:tap.speed");
    logbox("/evcharge/main", "tap.speed");

    setFilterTitle("충전속도(중복선택)");
    // setFilterSecond(false);
    setFilterSpeed(true);
    setFilterType(false);
    setFilterOperator(false);
    // setFilterAvailable(false);
    setFilterBottomSheet(true);
  };

  const filterSpeedExtra = (key, extraStatus) => {
    // alert(JSON.stringify(data))
    const objY = filterSpeedData.map(item => item.key === key ? { ...item, extraStatus: "Y" } : item);
    const objN = filterSpeedData.map(item => item.key === key ? { ...item, extraStatus: "N" } : item);

    if (extraStatus === "Y") {
      setFilterSpeedData(objN);
    } else if (extraStatus === "N") {
      setFilterSpeedData(objY);
    }
  }

  const filterSpeedReset = () => {
    const obj = filterSpeedData.map(item => item ? { ...item, extraStatus: "N" } : item);
    setFilterSpeedData(obj);
    // filterSpeedData.forEach(element => {
    //   element.extraStatus = "N";
    //   // alert(JSON.stringify(filterSpeedData))
    // });
  }

  const filterSpeedApply = () => {
    const obj = filterSpeedData.map(item => item.status !== item.extraStatus ? { ...item, status: item.extraStatus } : item)
    setFilterSpeedData(obj);
    store.dispatch(appActions.setSpeedFilterFirstValue(obj));
    // filterSpeedData.forEach(element => {
    //   element.status = element.extraStatus
    // })

    const speedName = obj.filter((item) => item.status === "Y");

    setFilterSpeedName(
      speedName.length === 0
        ?
        "충전속도"
        :
        speedName.length > 1
          ?
          speedName[0].key + " 외" + (speedName.length - 1)
          :
          speedName[0].key
    );

    const speedFilter = obj.filter(item => item.status === "Y").map(item => item.value)
    // console.log(speedFilter.toString())
    store.dispatch(appActions.setSpeedFilterValue(speedFilter.toString()));
    setFilterApplyClick(true);
    setFilterSpeedValue(speedFilter.toString());
    setFilterBottomSheet(false);
  }

  //4번째 필터 적용
  const filterTypeStatus = () => {
    mixpanel("evcharge:/evcharge/main:tap.type");
    logbox("/evcharge/main", "tap.type");
    const obj = filterTypeData.map(item => item.status !== item.extraStatus ? { ...item, extraStatus: item.status } : item);
    setFilterTypeData(obj);

    setFilterTitle("충전타입(중복선택)");
    // setFilterSecond(false);
    setFilterSpeed(false);
    setFilterType(true);
    setFilterOperator(false);
    //setFilterAvailable(false);
    setFilterBottomSheet(true);
  };

  const filterTypeExtra = (key, extraStatus) => {
    // alert(JSON.stringify(data))
    const objY = filterTypeData.map(item => item.key === key ? { ...item, extraStatus: "Y" } : item);
    const objN = filterTypeData.map(item => item.key === key ? { ...item, extraStatus: "N" } : item);

    if (extraStatus === "Y") {
      setFilterTypeData(objN);
    } else if (extraStatus === "N") {
      setFilterTypeData(objY);
    }
  }

  const filterTypeReset = () => {
    const obj = filterTypeData.map(item => item ? { ...item, extraStatus: "N" } : item);
    setFilterTypeData(obj);
  }

  const filterTypeApply = () => {

    const obj = filterTypeData.map(item => item.status !== item.extraStatus ? { ...item, status: item.extraStatus } : item)
    setFilterTypeData(obj);
    store.dispatch(appActions.setTypeFilterFirstValue(obj));

    const typeName = obj.filter((item) => item.status === "Y");

    setFilterTypeName(
      typeName.length === 0
        ?
        "충전타입"
        :
        typeName.length > 1
          ?
          typeName[0].key + " 외" + (typeName.length - 1)
          :
          typeName[0].key
    );

    const typeFilter = obj.filter(item => item.status === "Y").map(item => item.value)
    // console.log(speedFilter.toString())
    store.dispatch(appActions.setTypeFilterValue(typeFilter.toString()));
    setFilterApplyClick(true);
    setFilterTypeValue(typeFilter.toString());
    setFilterBottomSheet(false);
  }

  // 5번째 필터 적용
  const filterOperatorStatus = () => {
    mixpanel("evcharge:/evcharge/main:tap.cpo");
    logbox("/evcharge/main", "tap.cpo");

    const obj = filterOperatorData.map(item => item.status !== item.extraStatus ? { ...item, extraStatus: item.status } : item);
    setFilterOperatorData(obj);

    setFilterTitle("운영기관(중복선택)");
    // setFilterSecond(false);
    setFilterSpeed(false);
    setFilterType(false);
    setFilterOperator(true);
    //setFilterAvailable(false);
    setFilterBottomSheet(true);
  };

  const filterOperatorExtra = (cpoName, extraStatus) => {
    const objY = filterOperatorData.map(item => item.cpoName === cpoName ? { ...item, extraStatus: "Y" } : item);
    const objN = filterOperatorData.map(item => item.cpoName === cpoName ? { ...item, extraStatus: "N" } : item);

    if (extraStatus === "Y") {
      setFilterOperatorData(objN);
    } else if (extraStatus === "N") {
      setFilterOperatorData(objY);
    }
  }

  const filterOperatorReset = () => {
    const obj = filterOperatorData.map(item => item ? { ...item, extraStatus: "N" } : item);
    setFilterOperatorData(obj);
  }

  const filterOperatorApply = () => {

    const obj = filterOperatorData.map(item => item.status !== item.extraStatus ? { ...item, status: item.extraStatus } : item)
    setFilterOperatorData(obj);
    store.dispatch(appActions.setOperatorFilterFirstValue(obj));

    const operatorName = obj.filter((item) => item.status === "Y");

    setFilterOperatorName(
      operatorName.length === 0
        ?
        "운영기관"
        :
        operatorName.length > 1
          ?
          operatorName[0].cpoName + " 외" + (operatorName.length - 1)
          :
          operatorName[0].cpoName
    );

    const operatorFilter = obj.filter(item => item.status === "Y").map(item => item.cpoCode)
    // console.log(speedFilter.toString())
    store.dispatch(appActions.setOperatorFilterValue(operatorFilter.toString()));
    setFilterApplyClick(true);
    setFilterOperatorValue(operatorFilter.toString());
    setFilterBottomSheet(false);
  }

  // 6번째 필터 적용
  const filterAvailableStatus = () => {
    mixpanel("evcharge:/evcharge/main:tap.status");
    logbox("/evcharge/main", "tap.status");

    if (refreshCurrent) {
      setRefreshStatus(true);
    } else {
      setRefreshStatus(false);
    }

    setFilterApplyClick(true);

    if (filterAvailableValue === "CHARGING_STANDBY") {
      setFilterAvailableValue("");
      store.dispatch(appActions.setAvailableFilterValue(""));
    } else if (filterAvailableValue === "") {
      setFilterAvailableValue("CHARGING_STANDBY");
      store.dispatch(appActions.setAvailableFilterValue("CHARGING_STANDBY"));
    }
  };

  const goPromotion = () => {
    mixpanel("evcharge:/evcharge/main:tap.shinhancardintro_detail");
    logbox("/evcharge/main", "tap.shinhancardintro_detail");

    props.history.replace("/shinhanPromotion");
  }

  useEffect(() => { //직접 이동 시 충전배달 버튼 삭제, 재검색
    if (dragCount > 0) {
      refreshInfo();
    }
  }, [onDragPosition]);

  function refreshInfo() {
    var lon = onDragPosition._lng;
    var lat = onDragPosition._lat;

    getTopTmap(lat, lon)
      .then(function (response: any) {

        const result = response;
        // setEvDeliveryButton(false);

        setRefreshName(result.addressInfo.gu_gun + ' ' + result.addressInfo.legalDong);
        setRefreshButton(true);

      })
      .catch(function (error: any) {
        if (Const.DBG_OK) {
          alert("충전소 정보 조회실패");
        }
        setRefreshButton(false);

        //alert('error - ' + error);
      });
  }
  const Qrstyle = {
    height: 24,
    width: 24,
    marginRight: 5,
    marginTop: 1
    // right:"3px",
    // position:"relative"
  };
  // 마커 클릭 리스트 이동 부분
  // const refs = stationInfo.reduce((acc, value) => {
  //   acc[value.pkey] = React.createRef();
  //   return acc;
  // }, {});


  var bannerSet = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    className: 'banner_slider_custom',
    dots: false
  }

  // const [clickStatus, setClickStatus] = useState(false)
  const [clickPkey, setClickPkey] = useState("");
  const [clickStatus, setClickStatus] = useState(false);

  const onClickCustomMarker = (pkey) => {
    mixpanel("evcharge:/evcharge/main:tap.mapicon");
    logbox("/evcharge/main", "tap.mapicon");
    // console.log("onClickCustomMarker : ", pkey);
    // setClickStatus(true);

    setClickStatus(true);
    setTimeout(() => {
      setClickStatus(false);
    }, 100);

    setClickPkey(pkey);

    // 스크롤 되는 부분
    // refs[pkey].current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    // });
  };

  const markerAvail = (item: any) => {
    const fastCount = item?.ev_chargers?.filter(x => (x.status === "CHARGING_STANDBY" || x.status === "UNCONFIRMED") && (x.charger_speed === "FAST" || x.charger_speed === "SUPER_FAST"));
    const slowCount = item?.ev_chargers?.filter(x => (x.status === "CHARGING_STANDBY" || x.status === "UNCONFIRMED") && x.charger_speed === "SLOW");

    // if (item?.ev_charger_count_info[0] === 0) { //slow
    //   // setFastExist(true); setState를 컴포넌트의 바디에서 직접 호출하면 컴포넌트가 렌더링될 때마다 state가 변경되어 무한 루프에 빠질 수 있다.
    // } else if (item?.ev_chargers?.filter(x => x.charger_speed === "SLOW").length === 0) {
    //   // setSlowExist(true);
    // }

    return (
      <>
        {(item?.ev_charger_count_info[1]?.total_count + item?.ev_charger_count_info[2]?.total_count) === 0 ?
          <span style={globalVars.isIOS ? (item.tmap_private_ev_yn === "Y" ? { paddingLeft: "15px", top: "1px", position: "relative" } : { top: "1px", position: "relative" }) : (item.tmap_private_ev_yn === "Y" ? { paddingLeft: "15px", top: "0.5px", position: "relative" } : { top: "0.5px", position: "relative" })}>
            <span style={{ color: item.selected ? "#E0E2E8" : "#6E747A" }}>완</span>&nbsp;{slowCount.length}
          </span>
          :
          item?.ev_charger_count_info[0]?.total_count === 0 ? //slow
            <span className="fast" style={globalVars.isIOS ? (item.tmap_private_ev_yn === "Y" ? { paddingLeft: "15px", paddingRight: "0", top: "1px", position: "relative" } : { paddingRight: "0", top: "1px", position: "relative" }) : (item.tmap_private_ev_yn === "Y" ? { paddingLeft: "15px", paddingRight: "0", top: "0.5px", position: "relative" } : { paddingRight: "0", top: "0.5px", position: "relative" })}>
              <span style={{ color: item.selected ? "#E0E2E8" : "#6E747A" }}>급</span>&nbsp;{fastCount.length}
            </span>
            :
            <>
              <span style={globalVars.isIOS ? { top: "1px", position: "relative" } : { top: "0.5px", position: "relative" }}>
                <span className="fast" style={item.tmap_private_ev_yn === "Y" ? { paddingLeft: "15px" } : {}}>
                  <span style={{ color: item.selected ? "#E0E2E8" : "#6E747A" }}>급</span>&nbsp;{fastCount.length}
                </span>
                <img src={require(`%/images/common/bar.svg`).default} alt="" style={{ width: "2px", height: "13px", position: "relative", right: "4.5px", top: "2px" }} />
                <span><span style={{ color: item.selected ? "#E0E2E8" : "#6E747A" }}>완</span>&nbsp;{slowCount.length}</span>
              </span>
            </>
        }
      </>
    )
  }

  useEffect(() => {
    // if(clickPkey){
    const station = [
      ...stationInfo
        .map((item, index) => {
          if (item.pkey === clickPkey) {
            mapRef.current?.getCamera().panTo({ lng: item.center_wgs84_lon, lat: item.center_wgs84_lat }, { duration: 500 });
          }
          return item.pkey === clickPkey ? { ...item, selected: true } : { ...item, selected: false };
        }),
    ];

    // 겹쳐있는 선택한 충전소 가장 앞으로 보이게 하는 설정
    parentMarkerNode?.current.map((item, index) => {
      item.parentNode.style.zIndex = station[index]?.selected ? 9 : 0;
      // item.parentNode.style.top = '-30px';
    });

    setFocusStationInfo(station.filter((x) => x.selected));
    setStationInfo(station);

    if (clickPkey) {
      sheetRef.current?.snapTo(({ maxHeight }) => maxHeight * 0)
      setStationSheet(true);
    }
    // }
  }, [clickPkey])

  function renderChargerInfo(x, chargerInfo, iconClass1, chargerSpeed) {
    if (chargerInfo?.total_count === 0) {
      return <></>;
    }

    const availableCount = chargerInfo?.available_count === undefined ?
      0
      :
      x.ev_chargers?.filter(charger => (charger.status === "CHARGING_STANDBY" || charger.status === "UNCONFIRMED") && charger.charger_speed === chargerSpeed).length;

    return (
      <>
        <em className={iconClass1} />
        <em style={{ paddingRight: "7px", marginLeft: chargerSpeed === "SUPER_FAST" ? "11px" : "" }}>
          {availableCount} 대 가능
        </em>
      </>
    );
  }

  // const [lineSelected, setLineSelected] = useState<any>([]);
  // const [lineSelectedIndex, setLineSelectedIndex] = useState<any>([]);
  const contentRef = useRef([]) as any;
  const lineRef = useRef(null) as any;
  // const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  // const [margin, setMargin] = useState<number>(0);

  // const [dragging, setDragging] = useState(false);
  // const [dragSelected, setDragSelected] = useState(0);

  const [dragStart, setDragStart] = useState(false);

  const [touchStart, setTouchStart] = useState(false);

  const parentMarkerNode = useRef([]) as any;

  const goRepayInfo = () => {
    mixpanel("evcharge:/evcharge/main:tap.rebilling");
    logbox("/evcharge/main", "tap.rebilling");

    props.history.replace('/repayInfo');
  }

  const goBannerInfo = (value: string) => {
    switch (value) {
      case "EV_CHARGE_INFO":
        mixpanel("evcharge:/evcharge/main:tap.useguide");
        logbox("/evcharge/main", "tap.useguide");
				props.history.replace("/mainInfo");
				break;
			case "REPAYMENT":
				mixpanel("evcharge:/evcharge/main:tap.rebilling");
        logbox("/evcharge/main", "tap.rebilling");
        props.history.replace('/repayInfo');
				break;
			case "PNC":
				mixpanel("evcharge:/evcharge/main:tap.autocharge");
        logbox("/evcharge/main", "tap.autocharge");
        props.history.replace('/pncInfo');
				break;
      case "SE_80_PERCENT":
        mixpanel("evcharge:/evcharge/main:tap.seoulnotice");
        logbox("/evcharge/main", "tap.seoulnotice");
        tmapInterface.openBrowser(
					"tmap://communication?pageid=noticeDetail&seq=268"
				);
        break;
			default:
				break;
		}
  }

  const bannerClassName = (value: string) => {
    switch (value) {
      case "EV_CHARGE_INFO":
        return "main-banner-box"
			case "REPAYMENT":
				return "main-banner-box2"
			case "PNC":
				return "main-banner-box3"
      case "SE_80_PERCENT":
        return "main-banner-box4"
			default:
				return "main-banner-box"
		}
  }

  const bannerHeadName = (value: string) => {
    switch (value) {
      case "EV_CHARGE_INFO":
        return "전기차충전 이용안내"
			case "REPAYMENT":
				return "일부 충전요금 재결제 안내"
			case "PNC":
				return "인증없이 커넥터만 연결하세요!"
      case "SE_80_PERCENT":
        return "서울시 충전기 이용안내"
			default:
				return "전기차충전 이용안내"
		}
  }

  const bannerName = (value: string) => {
    switch (value) {
      case "EV_CHARGE_INFO":
        return "티맵 전기차충전! 이렇게 이용하세요."
			case "REPAYMENT":
				return "비정상 충전요금 재결제 예정"
			case "PNC":
				return "오토차지 충전 서비스 오픈"
      case "SE_80_PERCENT":
        return "일부 100kW 이상 급속 충전기 80% 충전 제한"
			default:
				return "티맵 전기차충전! 이렇게 이용하세요."
		}
  }

  // const [zoomLevel, setZoomLevel] = useState(13);
  // const [zoomSelected, setZoomSelected] = useState(false);

  // const [lonZoomSelected, setLonZoomSelected] = useState(false);
  // const [latZoomSelected, setLatZoomSelected] = useState(false);
  // const [lonZoomLevel, setLonZoomLevel] = useState(0);
  // const [latZoomLevel, setLatZoomLevel] = useState(0);
  // const [defaultZoom, setDefaultZoom] = useState(false);

  // const [selectedLon, setSelectedLon] = useState(0);
  // const [selectedLat, setSelectedLat] = useState(0);

  // const [currentRefresh, setCurrentRefresh] = useState(false);

  // const lonZoomOut = (lon) => {
  //   if (lon > 0.099 || lonZoomLevel === 8) {
  //     console.log("8 lon : " + lon);
  //     setLonZoomLevel(8);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.085 && lon < 0.099) || lonZoomLevel === 9) { //0.0115
  //     console.log("9 lon : " + lon);
  //     setLonZoomLevel(9);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.072 && lon < 0.085) || lonZoomLevel === 9.5) { //0.0105
  //     console.log("9.5 lon : " + lon);
  //     setLonZoomLevel(9.5);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.06 && lon < 0.072) || lonZoomLevel === 10) { //0.0095
  //     console.log("10 lon : " + lon);
  //     setLonZoomLevel(10);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.049 && lon < 0.06) || lonZoomLevel === 10.5) { //0.0085
  //     console.log("10.5 lon : " + lon);
  //     setLonZoomLevel(10.5);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.039 && lon < 0.049) || lonZoomLevel === 11) { //0.0065
  //     console.log("11 lon : " + lon);
  //     setLonZoomLevel(11);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.03 && lon < 0.039) || lonZoomLevel === 11.5) { //0.0055
  //     console.log("11.5 lon : " + lon);
  //     setLonZoomLevel(11.5);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.022 && lon < 0.03) || lonZoomLevel === 12) { //0.0045
  //     console.log("12 lon : " + lon);
  //     setLonZoomLevel(12);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.015 && lon < 0.022) || lonZoomLevel === 12.5) { //0.0035
  //     console.log("12.5 lon : " + lon);
  //     setLonZoomLevel(12.5);
  //     setLonZoomSelected(true);
  //   } else if (lon < 0.015 || lonZoomLevel === 13) {
  //     console.log("13 lon : " + lon);
  //     setLonZoomLevel(13);
  //     setLonZoomSelected(true);
  //   }
  // }

  // const latZoomOut = (lat) => {
  //   if (lat > 0.077 || latZoomLevel === 8) {
  //     console.log("8 lat : " + lat);
  //     setLatZoomLevel(8);
  //     setLatZoomSelected(true);
  //   } else if ((lat > 0.0655 && lat < 0.077) || latZoomLevel === 9) { //0.0115
  //     console.log("9 lat : " + lat);
  //     setLatZoomLevel(9);
  //     setLatZoomSelected(true);
  //   } else if ((lat > 0.055 && lat < 0.0655) || latZoomLevel === 9.5) { //0.0105
  //     console.log("9.5 lat : " + lat);
  //     setLatZoomLevel(9.5);
  //     setLatZoomSelected(true);
  //   } else if ((lat > 0.0375 && lat < 0.055) || latZoomLevel === 10) { //0.0095
  //     console.log("10 lat : " + lat);
  //     setLatZoomLevel(10);
  //     setLatZoomSelected(true);
  //   } else if ((lat > 0.029 && lat < 0.0375) || latZoomLevel === 10.5) { //0.0085
  //     console.log("10.5 lat : " + lat);
  //     setLatZoomLevel(10.5);
  //     setLatZoomSelected(true);
  //   } else if ((lat > 0.0225 && lat < 0.029) || latZoomLevel === 11) { //0.0065
  //     console.log("11 lat : " + lat);
  //     setLatZoomLevel(11);
  //     setLatZoomSelected(true);
  //   } else if ((lat > 0.017 && lat < 0.0225) || latZoomLevel === 11.5) { //0.0055
  //     console.log("11.5 lat : " + lat);
  //     setLatZoomLevel(11.5);
  //     setLatZoomSelected(true);
  //   } else if ((lat > 0.0115 && lat < 0.017) || latZoomLevel === 12) { //0.0045
  //     console.log("12 lat : " + lat);
  //     setLatZoomLevel(12);
  //     setLatZoomSelected(true);
  //   } else if ((lat > 0.009 && lat < 0.0115) || latZoomLevel === 12.5) { //0.0035
  //     console.log("12.5 lat : " + lat);
  //     setLatZoomLevel(12.5);
  //     setLatZoomSelected(true);
  //   } else if (lat < 0.009 || latZoomLevel === 13) {
  //     console.log("13 lat : " + lat);
  //     setLatZoomLevel(13);
  //     setLatZoomSelected(true);
  //   }
  // }

  // const lonZoomOutfold = (lon) => {
  //   if ((lon > 0.09 || lonZoomLevel === 10)) { //0.0045
  //     console.log("10 lon : " + lon);
  //     setLonZoomLevel(10);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.045 && 0.09 || lonZoomLevel === 10.5)) { //0.0045
  //     console.log("10.5 lon : " + lon);
  //     setLonZoomLevel(10.5);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.03 && lon < 0.045 || lonZoomLevel === 11)) { //0.0045
  //     console.log("11 lon : " + lon);
  //     setLonZoomLevel(11);
  //     setLonZoomSelected(true);
  //   } else if ((lon > 0.02 && lon < 0.03) || lonZoomLevel === 12) { //0.0035
  //     console.log("12 lon : " + lon);
  //     setLonZoomLevel(12);
  //     setLonZoomSelected(true);
  //   } else if (lon < 0.02 || lonZoomLevel === 13) {
  //     console.log("13 lon : " + lon);
  //     setLonZoomLevel(13);
  //     setLonZoomSelected(true);
  //   }
  // }

  // useEffect(() => {
  //   // console.log(lineSelected);
  //   if (clickStatus) {
  //     setClickStatus(false);

  //     const stationFilter = stationInfo.filter((x) => x.distance <= 10000);

  //     const station = [
  //       ...stationInfo
  //         // .filter((x) => x.distance <= 10000)
  //         .map((item, index) => {
  //           if (dragging === false) {
  //             // if (stationFilter.length < 10 && stationFilter.length > 5 && defaultZoom == false) {
  //             //   setDefaultZoom(true);
  //             //   setLonZoomLevel(10);
  //             //   setLatZoomLevel(10);
  //             // } else if (stationFilter.length <= 5) {
  //             //   setDefaultZoom(true);
  //             //   setLonZoomLevel(9);
  //             //   setLatZoomLevel(9);
  //             // }

  //             if (lineSelected[index] === true) {
  //               setDragSelected(index);
  //               if (selectedLon !== item.center_wgs84_lon && selectedLat !== item.center_wgs84_lat) {
  //                 setSelectedLon(item.center_wgs84_lon);
  //                 setSelectedLat(item.center_wgs84_lat);
  //                 // if(Math.abs(currentPositionLon - item.center_wgs84_lon)) {
  //                 if (Number(window.innerWidth > 440)) {
  //                   lonZoomOutfold(Math.abs(currentPositionLon - item.center_wgs84_lon));
  //                 } else {
  //                   lonZoomOut(Math.abs(currentPositionLon - item.center_wgs84_lon))
  //                 }
  //                 latZoomOut(Math.abs(currentPositionLat - item.center_wgs84_lat));
  //               } else {
  //                 if (currentRefresh) {
  //                   setCurrentRefresh(false);
  //                   if (Number(window.innerWidth > 440)) {
  //                     lonZoomOutfold(Math.abs(currentPositionLon - item.center_wgs84_lon));
  //                   } else {
  //                     lonZoomOut(Math.abs(currentPositionLon - item.center_wgs84_lon))
  //                   }
  //                   latZoomOut(Math.abs(currentPositionLat - item.center_wgs84_lat));
  //                 }
  //               }
  //             }
  //           } else {
  //             if (lineSelected[index] === true) {
  //               setDragSelected(index);
  //               if (dragSelected !== index) {
  //                 console.log("selec : " + dragSelected);
  //                 console.log("index : " + index);
  //                 // currentPositionLon = item.center_wgs84_lon;
  //                 // currentPositionLat = item.center_wgs84_lat;
  //                 dragCount++;
  //                 setOnDragPosition({ _lng: item.center_wgs84_lon, _lat: item.center_wgs84_lat });
  //                 mapRef.current?.getCamera().panTo({ lng: item.center_wgs84_lon, lat: item.center_wgs84_lat }, { duration: 500 });
  //               }
  //             }
  //           }
  //           return item.pkey === clickPkey ? { ...item, selected: true } : { ...item, selected: false };
  //         }),
  //     ];

  //     // 겹쳐있는 선택한 충전소 가장 앞으로 보이게 하는 설정
  //     parentMarkerNode?.current.map((item, index) => station[index]?.selected ? item.parentNode.style.zIndex = 99999 : item.parentNode.style.zIndex = 0);

  //     setStationInfo(station);
  //   } else {
  //     const lineSelect = lineSelected.every((value, idx) => value === lineSelectedIndex[idx]);

  //     const stationFilter = stationInfo.filter((x) => x.distance <= 10000);

  //     if (lineSelect) {
  //       const station = [
  //         ...stationInfo
  //           // .filter((x) => x.distance <= 10000)
  //           .map((item, index) => {
  //             if (dragging === false) {
  //               // if (stationFilter.length < 10 && stationFilter.length > 5 && defaultZoom == false) {
  //               //   setDefaultZoom(true);
  //               //   setLonZoomLevel(10);
  //               //   setLatZoomLevel(10);
  //               // } else if (stationFilter.length <= 5) {
  //               //   setDefaultZoom(true);
  //               //   setLonZoomLevel(9);
  //               //   setLatZoomLevel(9);
  //               // }

  //               if (lineSelected[index] === true) {
  //                 setDragSelected(index);
  //                 if (selectedLon !== item.center_wgs84_lon && selectedLat !== item.center_wgs84_lat) {
  //                   setSelectedLon(item.center_wgs84_lon);
  //                   setSelectedLat(item.center_wgs84_lat);
  //                   // if(Math.abs(currentPositionLon - item.center_wgs84_lon)) {
  //                   if (Number(window.innerWidth > 440)) {
  //                     lonZoomOutfold(Math.abs(currentPositionLon - item.center_wgs84_lon));
  //                   } else {
  //                     lonZoomOut(Math.abs(currentPositionLon - item.center_wgs84_lon))
  //                   }
  //                   latZoomOut(Math.abs(currentPositionLat - item.center_wgs84_lat));
  //                 } else {
  //                   if (currentRefresh) {
  //                     setCurrentRefresh(false);
  //                     if (Number(window.innerWidth > 440)) {
  //                       lonZoomOutfold(Math.abs(currentPositionLon - item.center_wgs84_lon));
  //                     } else {
  //                       lonZoomOut(Math.abs(currentPositionLon - item.center_wgs84_lon))
  //                     }
  //                     latZoomOut(Math.abs(currentPositionLat - item.center_wgs84_lat));
  //                   }
  //                 }
  //               }
  //             } else {
  //               if (lineSelected[index] === true) {
  //                 setDragSelected(index);
  //                 if (dragSelected !== index) {
  //                   console.log("selec : " + dragSelected);
  //                   console.log("index : " + index);
  //                   // currentPositionLon = item.center_wgs84_lon;
  //                   // currentPositionLat = item.center_wgs84_lat;
  //                   dragCount++;
  //                   setOnDragPosition({ _lng: item.center_wgs84_lon, _lat: item.center_wgs84_lat });
  //                   mapRef.current?.getCamera().panTo({ lng: item.center_wgs84_lon, lat: item.center_wgs84_lat }, { duration: 500 });
  //                 }
  //               }
  //             }
  //             return lineSelected[index] === true ? { ...item, selected: true } : { ...item, selected: false };
  //           }),
  //       ];

  //       // 겹쳐있는 선택한 충전소 가장 앞으로 보이게 하는 설정
  //       parentMarkerNode?.current.map((item, index) => station[index]?.selected ? item.parentNode.style.zIndex = 99999 : item.parentNode.style.zIndex = 0);

  //       setStationInfo(station);

  //     } else {
  //       setLineSelectedIndex(lineSelected);
  //     }
  //   }
  // }, [lineSelected]);

  // useEffect(() => {
  //   if (lonZoomSelected && latZoomSelected) {
  //     console.log("lonzoomlevel : " + lonZoomLevel);
  //     console.log("latzoomlevel : " + latZoomLevel);
  //     console.log("min : " + Math.min(lonZoomLevel, latZoomLevel))
  //     setLonZoomSelected(false);
  //     setLatZoomSelected(false);
  //     mapRef.current?.getCamera().zoomTo(Math.min(lonZoomLevel, latZoomLevel), { duration: 1000 });
  //     // if (
  //     //   position.center[0] === currentPositionLon &&
  //     //   position.center[1] === currentPositionLat
  //     // ) {
  //     //   setPosition({
  //     //     center: [
  //     //       currentPositionLon + 0.00001,
  //     //       currentPositionLat + 0.00001,
  //     //     ], //127.0, 37.5 126.9556914, 37.3834682
  //     //     zoom: Math.min(lonZoomLevel, latZoomLevel),
  //     //     bearing: 0,
  //     //     pitch: 0,
  //     //   });
  //     // } else {
  //     //   setPosition({
  //     //     center: [
  //     //       currentPositionLon,
  //     //       currentPositionLat,
  //     //     ], //127.0, 37.5 126.9556914, 37.3834682
  //     //     zoom: Math.min(lonZoomLevel, latZoomLevel),
  //     //     bearing: 0,
  //     //     pitch: 0,
  //     //   });
  //     // }
  //   }
  // }, [lonZoomSelected, latZoomSelected])

  // 리스트 포커스 기능
  // useEffect(() => {

  //   if (!contentRef.current || !lineRef.current) return;

  //   if (contentRef !== null && lineRef !== null) {

  //     const temp = contentRef?.current.map((item) => (item?.getBoundingClientRect().top <= lineRef?.current?.getBoundingClientRect().top + 38 || item?.getBoundingClientRect().bottom - 40 <= lineRef?.current?.getBoundingClientRect().top) && (item?.getBoundingClientRect().top >= lineRef?.current?.getBoundingClientRect().top + 38 || item?.getBoundingClientRect().bottom - 40 >= lineRef?.current?.getBoundingClientRect().top));

  //     if (temp.length <= 0) return;

  //     const timeout = setInterval(() => {
  //       setLineSelected(temp);
  //     }, 200);

  //     return () => clearInterval(timeout);
  //   }

  // });

  // 리스트 포커스 기능
  // useEffect(() => {
  //   // console.log(contentRef?.current[0]?.getBoundingClientRect());

  //   if (margin <= 0 || isNaN(margin)) {

  //     // console.log(contentRef?.current[0]?.getBoundingClientRect().bottom - contentRef?.current[0]?.getBoundingClientRect().top);

  //     // 화면 높이 - 기준점 높이 - 충전소 element 높이 - QR 촬영 버튼 높이
  //     const variableMargin = windowHeight - lineRef?.current?.getBoundingClientRect().top - 90;

  //     setMargin(variableMargin);
  //   }

  // }, [lineSelected]);

  if (!inited) {
    return null;
} else {
  return (
    <>
      <HandleBrowserBackButton />
      {/* <Header title={"전기차충전"} isMenu={false} isActive={"3"} /> */}
      {/* Body Start*/}
      {/* <div id="page"> */}
      <div
        className="charge-map"
        style={{ position: "relative", zIndex: "5" }}
      >
        <div className="search" style={clickBottomSheetList ? { height: "80px", width: "100%" } : stationSheet ? {} : { width: "100%" }}>
          <img src={require(`%/images/common/back-circle.png`).default} alt="" style={focusBackBtn ? { width: "54.2px", height: "54.2px", filter: "brightness(90%)" } : { width: "54.2px", height: "54.2px" }} onClick={goBack} onTouchStart={() => setFocusBackBtn(true)} onTouchEnd={() => setFocusBackBtn(false)} />
          {/* <img src={require(`%/images/search-button.svg`).default} alt="" /> */}

          {/* <i></i> */}
          {
            !stationSheet
              ?
              <input type="text" placeholder="목적지 주변 충전소 찾기" onClick={goSearch} onTouchStart={() => setFocusSearch(true)} onTouchEnd={() => setFocusSearch(false)} style={focusSearch ? { filter: "brightness(90%)" } : {}} />
              :
              <></>
          }
          {/* <button type="button">검색</button> */}
        </div>
        {/* {evDeliveryButton && clickBottomSheetList === false && !stationSheet ? (
          <button //충전배달 신청 버튼 임시 생성
            className="delivery-button"
            onClick={() => {
              goEvDelivery();
            }}
            onTouchStart={() => setFocusDeliBtn(true)} onTouchEnd={() => setFocusDeliBtn(false)} style={focusDeliBtn ? { filter: "brightness(90%)" } : {}}
          >
            <span>충전배달</span>
          </button>
        ) : (
          <></>
        )} */}
        <div className="map-box">
          <div className="button-group">
            <button type="button" onClick={goChargeCurrentStatus}
              onTouchStart={() => setFocusCurrentStatus(true)} onTouchEnd={() => setFocusCurrentStatus(false)}
              style={focusCurrntStatus ? { filter: "brightness(90%)", width: "30%" } : { width: "30%" }}
            >
              {" "}
              충전현황
            </button>
            <button type="button" onClick={()=>goStartQrChagingView()}
              onTouchStart={() => setFocusCharging(true)} onTouchEnd={() => setFocusCharging(false)}
              style={focusCharging ? { filter: "brightness(90%)", width: "68%", left: "2%", backgroundColor: "#0064ff", color: "#fff" } : { width: "68%", left: "2%", backgroundColor: "#0064ff", color: "#fff" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Lottie animationData={QrAnimation} style={Qrstyle} />
                <span style={{ fontFamily: "TMOBI500" }}>충전 시작하기</span>
              </div>
            </button>
          </div>
          {isBoolean(slidePopVisible) && (
            <div
              className="charge-popup"
              style={{ visibility: slidePopVisible ? "visible" : "hidden" }}
            >
              <div className="popup-box">
                <div className="popup-wrap">
                  <div className="charge-popcontents">
                    <div className="pay-info-img">
                      <div className="top">
                        <figure>
                          <Slider {...settings}>
                            {popupList.map((item, index) => {
                              return (
                                <div key={index} onClick={() => popupLink(item.popupCode, index)}>
                                  <img src={require(`%/images/popup/${item.popupImage}`).default} alt="" />
                                </div>
                              )
                            }
                            )}
                          </Slider>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="checkbox-type1">
                  <button className="mainChkpopup" onClick={() => popupState()}>다시 보지 않기</button>
                  <button className="closePop" onClick={() => closePopup()}>닫기</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Map
        style={{
          width: "100vw", //width
          height: "100vh", //100%
          position: "fixed",
          zIndex: "1",
          bottom: "100px",
        }}
        config="https://farm41.ids.onestore.co.kr/hub/vsm/config/application/S001/TMAP_JS.conf"
        position={position}
        forwardedRef={mapRef}
      >
        {stationInfo[0]?.distance <= 10000 && isNull === false ? (
          <>
            {stationInfo &&
              stationInfo
                .filter((x) => x.distance <= 10000)
                .map((item, index) => {
                  //충전소 마커 임시 값 a로 우선 호출 -> axios 통신 후 나중에 값 다 불러오면 테스트 진행
                  return (
                    <>
                      <CustomMarker
                        lngLat={[item.center_wgs84_lon, item.center_wgs84_lat]}
                        anchor="center"
                        key={item.pkey}
                        style={{ top: "-30px" }}
                      >
                        <div ref={(el) => {
                          if (el) {
                            parentMarkerNode.current[index] = el;
                            updateZIndex();
                          }
                        }}>
                          <div
                            style={{
                              display: "flex",
                              position: "relative",
                              justifyContent: "center"
                            }}
                          >
                            <div className={
                              item.selected ?
                                `pin-marker-select 
                                ${globalVars.isIOS ? "" : item.tmap_private_ev_yn === "Y" ? "and-before-select" : "and-short-before-select"}
                                 gr
                                ${(item?.ev_charger_count_info[1]?.total_count + item?.ev_charger_count_info[2]?.total_count === 0) || item?.ev_charger_count_info[0]?.total_count === 0 ? (item.tmap_private_ev_yn === "Y" ? "t-short" : "short") : ""}
                                ${item.tmap_private_ev_yn !== "Y" && item?.ev_charger_count_info[1]?.total_count + item?.ev_charger_count_info[2]?.total_count > 0 && item?.ev_charger_count_info[0]?.total_count > 0 ? "long-marker" : ""}
                                `
                                :
                                `pin-marker ${globalVars.isIOS ? "" : item.tmap_private_ev_yn === "Y" ? "and-before" : "and-short-before"} gr ${(item?.ev_charger_count_info[1]?.total_count + item?.ev_charger_count_info[2]?.total_count === 0) || item?.ev_charger_count_info[0]?.total_count === 0 ? (item.tmap_private_ev_yn === "Y" ? "t-short" : "short") : ""}
                                ${item.tmap_private_ev_yn !== "Y" && item?.ev_charger_count_info[1]?.total_count + item?.ev_charger_count_info[2]?.total_count > 0 && item?.ev_charger_count_info[0]?.total_count > 0 ? "long-marker" : ""}
                                `
                            }
                              onClick={() => {
                                onClickCustomMarker(item.pkey);
                              }}
                            >
                              {
                                item.pncIncludeYn ?
                                  <div className="pnc-tag">
                                    <p>오토차지</p>
                                  </div>
                                  :
                                  <></>
                              }
                              {
                                item.tmap_private_ev_yn === "Y" ?
                                  <img src={require(`%/images/common/${item.selected ? "TMAP_Logo_white" : "TMAP_Logo"}.svg`).default} alt="" style={{ position: "absolute", left: "5px" }} />
                                  :
                                  <></>
                              }
                              {markerAvail(item)}
                            </div>
                          </div>
                        </div>
                      </CustomMarker>
                    </>
                  );
                })}
          </>
        ) : (
          <></>
        )}

        <CustomMarker lngLat={[currentPointLon, currentPointLat]} anchor="center">
          <img style={{ width: "42px" }}
            src={
              require("%/images/common/ic-searchbar-current-position.png")
                .default
            }
            alt=""
          />
        </CustomMarker>

        <MapEventListener
          name={Vsm.Map.EventNames.FirstPaint}
          listener={() => {
            const elapsedTime = Math.trunc(performance.now() - startTime);
            console.log(`FirstPaint - ${elapsedTime} ms`);
          }}
        />

        {!firstRenderComplete && (
          <MapEventListener
            name={Vsm.Map.EventNames.RenderComplete}
            listener={() => {
              const elapsedTime = Math.trunc(performance.now() - startTime);
              console.log(`RenderComplete - ${elapsedTime} ms`);

              setFirstRenderComplete(true);
            }}
          />
        )}

        <MapEventListener //실시간 좌표
          name={Vsm.Map.EventNames.Render}
          listener={(event: Vsm.Event) => {
            const { target: map } = event as Vsm.MapEvent;
            const { zoom, center, bearing, pitch } = getCurrentPosition(map);

            setStatus(center.toString());
          }}
        />

        {
          dragStart
            ?
            <MapEventListener //지도 이동 후 api 호출
              name={Vsm.Map.EventNames.MoveEnd} // 지도 이동 끝
              listener={(event: Vsm.Event) => {
                setDragStart(false);
                // setDragging(true);
                window.setTimeout(() => {
                  const { target: map } = event as Vsm.MapEvent;
                  const { zoom, center, bearing, pitch } =
                    getCurrentPosition(map);
                  dragCount++;
                  setOnDragPosition(center);
                });
              }}
            />
            :
            <></>
        }

        {
          dragStart
            ?
            <MapEventListener //지도 이동 후 api 호출
              name={Vsm.Map.EventNames.ZoomEnd} // 줌 변경 끝
              listener={(event: Vsm.Event) => {
                setDragStart(false);
                // setDragging(true);

                // mapPosition();
              }}
            />
            :
            <></>
        }


        <MapEventListener
          name={Vsm.Map.EventNames.DragStart} // 드래그 이동 끝
          listener={(event: Vsm.Event) => {
            setDragStart(true);
          }}
        />

        <MapEventListener
          name={Vsm.Map.EventNames.ZoomStart} // 줌 이동 시작
          listener={(event: Vsm.Event) => {
            if (touchStart) {
              setTouchStart(false);
              setDragStart(true);
            }
          }}
        />

        {/* <MapEventListener //지도 이동 후 api 호출
            name={Vsm.Map.EventNames.DragEnd}
            listener={(event: Vsm.Event) => {
              setDragging(true);
              window.setTimeout(() => {
                const { target: map } = event as Vsm.MapEvent;
                const { zoom, center, bearing, pitch } =
                getCurrentPosition(map);
                dragCount++;
                setOnDragPosition(center);
              }, 500);
            }}
          /> */}

        {/* <MapEventListener //지도 이동 후 api 호출
            name={Vsm.Map.EventNames.ZoomEnd}
            listener={(event: Vsm.Event) => {
              setRefreshButton(false);
            }}
          /> */}

        <MapEventListener //지도 이동 후 api 호출
          name={Vsm.Map.EventNames.TouchStart} // 지도 터치 시작
          listener={(event: Vsm.Event) => {
            const { target: map } = event as Vsm.MapEvent;
            const { zoom, center, bearing, pitch } = getCurrentPosition(map);

            setCurrentPositionMode("1");
            setTouchStart(true);
            setCurrentButton(false);
            setRefreshCurrent(false); //지도 클릭 시 충전시작 버튼 비활성화 처리
            setCurrentButtonPosition(false);
          }}
        />

        <MapEventListener //지도 이동 후 api 호출
          name={Vsm.Map.EventNames.Click} // 지도 클릭 시
          listener={(event: Vsm.Event) => {
            const { target: map } = event as Vsm.MapEvent;
            const { zoom, center, bearing, pitch } = getCurrentPosition(map);
            //지도 클릭 시 충전소 시트 닫기
            if (!clickStatus) {
              onClickCustomMarker("")
              setStationSheet(false);
            }
          }}
        />

        {/* 확대 축소 및 여러 기능 버튼 */}
        {/* <MapControlDock name={Vsm.Map.ControlDockNames.TopRight}>
            <FullScreenControl />
            <HtmlElementControl contents={marginDiv} />
            <NavigationControl />
          </MapControlDock> */}

        {/* <CustomMarker lngLat={[126.9783882, 37.5666103]} anchor="right">
            <div //커스텀 마커 기본 형태
              style={{
                padding: "5px",
                backgroundColor: "blue",
                color: "white",
              }}
            >
              {`서울 시청 여기 어디쯤 =>`}
              <br />
            </div>
          </CustomMarker>

          <CustomMarker lngLat={[126.9726293, 37.5509146]} anchor="right">
            <div
              style={{
                padding: "3px",
              }}
              onClick={cMarker}
            >
              {`C충전기`}
            </div>
          </CustomMarker> */}
      </Map>

      <BottomSheet
        open={bottomSheet} //바텀시트 열기
        ref={sheetRef}
        scrollLocking={false}
        snapPoints={({ maxHeight }) => [1 * maxHeight, maxHeight * 0 + 310, clickPkey ? maxHeight * 0 : 310]
          // window.innerHeight <= 700
          //   ? [0.9 * maxHeight, maxHeight*0+320]
          //   : window.innerHeight <= 850
          //     ? [0.9 * maxHeight, maxHeight*0+320]
          //     : [0.9 * maxHeight, maxHeight*0+320]
        } // 높이 조절 기능 최대값 두개 설정해서 최상단 최하단 지정
        // defaultSnap={({ maxHeight }) => 0.45 * maxHeight}
        blocking={false} // 뒷 배경 화면 비활성화 기능
        expandOnContentDrag={contentDrag}
        style={{ display: bottomSheet ? "" : "none" }}
        // onSpringEnd={() => {
        //   if (sheetRef.current?.height === 300) {
        //     setClickBottomSheetList(false);
        //   } else {
        //     setClickBottomSheetList(true);
        //   }
        // }}
        onSpringStart={() => {
          requestAnimationFrame(() => {
            const toHeight = sheetRef.current?.height;
            const toDiffHeight = window.innerHeight - Number(toHeight);
            if (toDiffHeight < 100) {
              setClickBottomSheetList(true);
            } else {
              setClickBottomSheetList(false);
            }
          })
        }}
        // onSpringStart={() => {
        //   if (sheetRef.current?.height === 300) {
        //     // setClickBottomSheetList(false);
        //     console.log("ss")
        //   } else {
        //     console.log("zz")
        //     // setClickBottomSheetList(true);
        //   }
        // }}
        header={
          <div className="bottomsheet-header">
            {clickBottomSheetList ?
              <></>
              :
              <div className="header-bar" onClick={() => {
                sheetRef.current?.snapTo(({ maxHeight }) => clickBottomSheetList ? maxHeight * 0 + 310 : 1 * maxHeight)
              }}>
              </div>
            }
            <div className="header-text">
              {clickBottomSheetList ? <></> : <img src={require(`%/images/common/ic-bottomsheet-list.svg`).default} alt="" />}
              <span style={clickBottomSheetList ? { color: "#fff" } : { fontFamily: "TMOBI300", fontSize: "14px", color: "#51565C" }} onClick={() => {
                mixpanel("evcharge:/evcharge/main:tap.stationlist");
                logbox("/evcharge/main", "tap.stationlist");
                sheetRef.current?.snapTo(({ maxHeight }) => clickBottomSheetList ? maxHeight * 0 + 310 : 1 * maxHeight)
              }}>&nbsp;{`주변 `}
                <span className="count">{`${stationCount}개 `}</span>
                <span>{'충전소 보기'}</span>
              </span>
              {clickBottomSheetList === false && !stationSheet
                ?
                evDeliveryButton
                  ?
                  <button //충전배달 신청 버튼 임시 생성
                    className="delivery-button"
                    onClick={() => {
                      goEvDelivery();
                    }}
                    onTouchStart={() => setFocusDeliBtn(true)} onTouchEnd={() => setFocusDeliBtn(false)} style={focusDeliBtn ? { filter: "brightness(90%)" } : {}}
                  >
                    <span>충전배달</span>
                  </button>
                  :
                  <button //충전배달 신청 버튼 임시 생성
                    className="delivery-button"
                    style={{ backgroundColor: "#F8F9FA", }}
                  >
                    <span style={{ color: "#BEC5CC" }}>충전배달</span>
                  </button>
                :
                <></>
              }
            </div>
            {/* <div className="filter">
                <div className="inner-wrap">
                  {filterTmapValue === "Y" ? (
                    <button type="button" onClick={filterTmapStatus} style={{border : '1px solid #6E747A', fontFamily:"TMOBI500"}}>
                      티맵결제
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={filterTmapStatus}
                      style={focusTmap ? {filter:"brightness(90%)"} : { backgroundColor: "#fff", color: "#000" }}
                      onTouchStart={() => setFocusTmap(true)} onTouchEnd={() => setFocusTmap(false)}
                    >
                      티맵결제
                    </button>
                  )}
                  {filterPublicValue === "public" ? (
                    <button
                      type="button"
                      onClick={filterPublicStatus}
                      style={{
                        border : '1px solid #6E747A', 
                        fontFamily:"TMOBI500",
                        marginLeft: "5px",
                      }}
                    >
                      공용
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={filterPublicStatus}
                      onTouchStart={() => setFocusPublic(true)} onTouchEnd={() => setFocusPublic(false)} style={focusPublic ? {filter:"brightness(90%)", marginLeft: "5px",backgroundColor: "#fff",
                      color: "#000",}:{
                        marginLeft: "5px",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                    >
                      공용
                    </button>
                  )}
                  {filterSpeedName === "충전속도" ? (
                    <button
                      className="buttonN"
                      type="button"
                      onClick={filterSpeedStatus}
                      style={focusSpeed ? 
                        {filter:"brightness(90%)", marginLeft: "5px",backgroundColor: "#fff",
                        color: "#000"} 
                        : 
                        {
                        backgroundColor: "#fff",
                        color: "#000",
                        marginLeft: "5px",
                      }}
                      onTouchStart={() => setFocusSpeed(true)} onTouchEnd={() => setFocusSpeed(false)}
                    >
                      {filterSpeedName}
                    </button>
                  ) : (
                    <button
                      className="buttonY"
                      type="button"
                      onClick={filterSpeedStatus}
                      style={{ marginLeft: "5px" }}
                    >
                      {filterSpeedName}
                    </button>
                  )}
                  {filterTypeName === "충전타입" ? (
                    <button
                      className="buttonN"
                      type="button"
                      onClick={filterTypeStatus}
                      onTouchStart={() => setFocusType(true)} onTouchEnd={() => setFocusType(false)} style={focusType ? {filter:"brightness(90%)", marginLeft: "5px",backgroundColor: "#fff",
                      color: "#000"}:{marginLeft: "5px",backgroundColor: "#fff",
                      color: "#000",}}
                    >
                      {filterTypeName}
                    </button>
                  ) : (
                    <button
                      className="buttonY"
                      type="button"
                      onClick={filterTypeStatus}
                      style={{ marginLeft: "5px" }}
                    >
                      {filterTypeName}
                    </button>
                  )}
                  {filterOperatorName === "운영기관" ? (
                    <button
                      className="buttonN"
                      type="button"
                      onClick={filterOperatorStatus}
                      onTouchStart={() => setFocusCpo(true)} onTouchEnd={() => setFocusCpo(false)} style={focusCpo ? {filter:"brightness(90%)", marginLeft: "5px", backgroundColor: "#fff",
                      color: "#000"}:{marginLeft: "5px",backgroundColor: "#fff",
                      color: "#000",}}
                    >
                      {filterOperatorName}
                    </button>
                  ) : (
                    <button
                      className="buttonY"
                      type="button"
                      onClick={filterOperatorStatus}
                      style={{ marginLeft: "5px" }}
                    >
                      {filterOperatorName}
                    </button>
                  )}
                  {filterAvailableValue === "CHARGING_STANDBY" ? (
                    <button
                      type="button"
                      onClick={filterAvailableStatus}
                      style={{
                        fontFamily:"TMOBI500",
                        border : '1px solid #6E747A',
                        marginLeft: "5px",
                      }}
                    >
                      이용가능
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={filterAvailableStatus}
                      onTouchStart={() => setFocusUse(true)} onTouchEnd={() => setFocusUse(false)} 
                      style={focusUse ? {filter:"brightness(90%)",
                      marginLeft: "5px",backgroundColor: "#fff",
                      color: "#000"}:{
                      marginLeft: "5px",backgroundColor: "#fff",
                      color: "#000"}}
                    >
                      이용가능
                    </button>
                  )}
                </div>
              </div> */}
            {/* <img src={require(`%/images/bottomsheet-banner.svg`).default} alt="" style={{width:"100%"}}/> */}
          </div>
        }
      >
        <div className="util-group">
          {clickBottomSheetList
            ?
            <></>
            :
            currentPositionMode === "1" ? (
              <button //현재위치 버튼 임시 생성
                className="location-button"
                style={
                  focusLocationBtn ? {
                    WebkitAppearance: "none",
                    position: "absolute",
                    left: "7px",
                    top: "-65px",
                    zIndex: "5",
                    filter: "brightness(90%)"
                  }
                    :
                    {
                      WebkitAppearance: "none",
                      position: "absolute",
                      left: "7px",
                      top: "-65px",
                      zIndex: "5",
                    }}
                onClick={() => {
                  //setCurrentPositionMode("1");
                  setRefreshCurrent(true);
                  tmapInterface.getCurrentPosition(currentPositionRefresh);

                  mixpanel("evcharge:/evcharge/main:tap.location");
                  logbox("/evcharge/main", "tap.location");

                  // getLocation();
                  //getStationInfo();
                }}
                onDoubleClick={() => {
                  setCurrentPositionMode("2");
                  setRefreshCurrent(true);
                  tmapInterface.getCurrentPosition(currentPositionSecondMode);
                  // getLocation();
                  //getStationInfo();
                }}
                onTouchStart={() => setFocusLocationBtn(true)} onTouchEnd={() => setFocusLocationBtn(false)}
              >
                <img style={{ position: "relative", width: "52px",top: "3px" }}
                  src={require("%/images/common/btn-position.png").default}
                  alt=""
                />
              </button>
            ) : currentPositionMode === "2" ? (
              <button //현재위치 버튼 임시 생성
                className="location-button"
                style={{
                  WebkitAppearance: "none",
                  position: "absolute",
                  left: "7px",
                  top: "-65px",
                  zIndex: "5",
                }}
                onClick={() => {
                  setCurrentPositionMode("1");
                  setRefreshCurrent(true);
                  tmapInterface.getCurrentPosition(currentPositionRefresh);
                  // getLocation();
                  // getStationInfo();
                }}
              >
                <img style={{ position: "relative", width: "52px",top: "3px" }}
                  src={
                    require("%/images/common/btn-position-direction-on.png")
                      .default
                  }
                  alt=""
                />
              </button>
            ) : (
              <></>
            )}

          {refreshButton ? ( // 재검색 버튼
            <button
              className="refresh-button"
              style={
                focusCurrent ? {
                  WebkitAppearance: "none",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)", // %조정
                  background: "white",
                  zIndex: "5",
                  filter: "brightness(90%)",
                  top: "-60px"
                }
                  :
                  {
                    WebkitAppearance: "none",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)", // %조정
                    background: "white",
                    zIndex: "5",
                    top: "-60px"
                  }
              }
              onClick={() => {
                mixpanel("evcharge:/evcharge/main:tap.research");
                logbox("/evcharge/main", "tap.research");
                if (currentButton == false) {
                  currentPositionLon = onDragPosition._lng;
                  currentPositionLat = onDragPosition._lat;
                }
                setCurrentButton(false);
                // if (currentPositionLon == currentPointLon) {
                //   setCurrentRefresh(true);
                // }
                setTouchStart(false);
                // setZoomLevel(13);
                // setLonZoomLevel(13);
                // setLatZoomLevel(13);
                // setDefaultZoom(false);
                // setDragging(false);
                getStationInfo();
                if (currentPositionLon) {
                  EvDeliveryStatus();
                }
                setRefreshButton(false);
                setCurrentPositionMode("1");
                if (refreshCurrent) {
                  setRefreshStatus(true);
                } else {
                  setRefreshStatus(false);
                }
              }}
              onTouchStart={() => setFocusCurrent(true)} onTouchEnd={() => setFocusCurrent(false)}
            >
              <span style={{ whiteSpace: "nowrap" }}>
                <span style={{ color: "#0064ff" }}>
                  {Number(window.innerWidth) > 370
                    ?
                    <>
                      {refreshName.length <= 11
                        ? refreshName
                        : utils.textLengthOverCut(refreshName, 11, "···")
                      }
                    </>
                    :
                    <>
                      {Number(window.innerWidth) < 340 ?
                        <>
                          {refreshName.length <= 3
                            ? refreshName
                            : utils.textLengthOverCut(refreshName, 3, "···")
                          }
                        </>
                        :
                        <>
                          {refreshName.length <= 8
                            ? refreshName
                            : utils.textLengthOverCut(refreshName, 8, "···")
                          }
                        </>
                      }
                    </>
                  }
                </span>
                에서 재검색
              </span>
            </button>
          ) : (
            <></>
          )}

          {/* {clickBottomSheetList
            ?
            <></>
            :
            <>
              <button
                className="pnc-button"
                style={
                  focusPncBtn ? 
                  {
                    WebkitAppearance: "none",
                    position: "absolute",
                    left: "7px",
                    top: "-58px",
                    zIndex: "5",
                    opacity: "0.9"
                  }
                  :
                  {
                    WebkitAppearance: "none",
                    position: "absolute",
                    left: "7px",
                    top: "-58px",
                    zIndex: "5",
                  }
                }
                onClick={goPncInfo}
                onTouchStart={() => setFocusPncBtn(true)} onTouchEnd={() => setFocusPncBtn(false)}
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  오토차지
                  {
                    carInfo.result == true
                    ?
                    <span style={{ color: "#2BC0F8" }}>
                      &nbsp;사용중
                    </span>
                    :
                    <span style={{ color: "#BEC5CC" }}>
                      &nbsp;미등록
                    </span>
                  }
                </span>
              </button>
            </>
          } */}

          {/* <Popover
            isOpen={(clickBottomSheetList || stationSheet) ? false : popoverOpen}
            positions={['top']} // preferred positions by priority
            content={
              <div className="popover-content" onClick={() => {
                store.dispatch(appActions.setPopoverOpen(false));
                setPopoverOpen(false);
              }}>
                티맵카드로 최대 50% 할인
              </div>
              // <div style={{lineHeight: "1.2", background:"linear-gradient(31deg, #0064FF 33.17%, #34C7C2 86.88%)"}}>
              //   티맵카드로<br/>5만원 캐시백!
              //   <button onClick={() => {
              //     store.dispatch(appActions.setPopoverOpen(false));
              //     setPopoverOpen(false);
              //   }} />
              // </div>
            }
            containerStyle={{ top: "1px", left: "-8px" }}
            containerClassName="popover"
          > */}
          {clickBottomSheetList
            ?
            <></>
            :
            <button //결제수단 등록 화면으로 넘어가는 버튼 임시 생성
              className="credit-button"
              style={
                focusPay ?
                  {
                    WebkitAppearance: "none",
                    position: "absolute",
                    top: "-65px",
                    right: "7px",
                    zIndex: "5",
                    filter: "brightness(90%)"
                  }
                  :
                  {
                    WebkitAppearance: "none",
                    position: "absolute",
                    top: "-65px",
                    right: "7px",
                    zIndex: "5",
                  }
              }
              onClick={() => {
                goPayInfo();
              }}
              onTouchStart={() => setFocusPay(true)} onTouchEnd={() => setFocusPay(false)}
            >
              <img style={{ position: "relative", width: "52px",top: "3px" }} src={require("%/images/common/btn-payment.png").default} alt="" />
              {/* <div className="red-dot"></div> */}
            </button>
          }
          {/* </Popover> */}
        </div>

        {/* 기준점 */}
        <div ref={lineRef} style={{ position: "fixed", width: "100%" }}></div>

        <div
          id="bottom-sheet"
          className="main-bottomSheet"
          style={{ position: "relative", content: "asdf" }}
        >
          <div className="filter">
            <div className="inner-wrap">
              {<button type="button" onClick={filterTmapStatus}
                style={filterTmapValue === "Y"
                  ? focusTmap
                    ? { filter: "brightness(90%)", border: '1px solid #6E747A', fontFamily: "TMOBI500" }
                    : { border: '1px solid #6E747A', fontFamily: "TMOBI500" }
                  : focusTmap
                    ? { filter: "brightness(90%)", backgroundColor: "#fff", color: "#000" }
                    : { backgroundColor: "#fff", color: "#000" }
                }
                onTouchStart={() => {
                  setFocusTmap(true);
                  if (!clickBottomSheetList) {
                    setContentDrag(false);
                  }
                }}
                onTouchEnd={() => {
                  setFocusTmap(false);
                  setContentDrag(true);
                }}
              >
                티맵결제
              </button>}
              {<button type="button" onClick={filterPublicStatus}
                style={filterPublicValue === "public"
                  ? focusPublic
                    ? { filter: "brightness(90%)", border: '1px solid #6E747A', fontFamily: "TMOBI500", marginLeft: "5px" }
                    : { border: '1px solid #6E747A', fontFamily: "TMOBI500", marginLeft: "5px" }
                  : focusPublic
                    ? { filter: "brightness(90%)", backgroundColor: "#fff", color: "#000", marginLeft: "5px" }
                    : { backgroundColor: "#fff", color: "#000", marginLeft: "5px" }
                }
                onTouchStart={() => {
                  setFocusPublic(true);
                  if (!clickBottomSheetList) {
                    setContentDrag(false);
                  }
                }}
                onTouchEnd={() => {
                  setFocusPublic(false);
                  setContentDrag(true);
                }}
              >
                공용
              </button>}
              {<button className={filterSpeedName === "충전속도" ? "buttonN" : "buttonY"} type="button" onClick={filterSpeedStatus}
                style={filterSpeedName === "충전속도"
                  ? focusSpeed
                    ? { filter: "brightness(90%)", marginLeft: "5px", backgroundColor: "#fff", color: "#000" }
                    : { marginLeft: "5px", backgroundColor: "#fff", color: "#000" }
                  : focusSpeed
                    ? { filter: "brightness(90%)", marginLeft: "5px" }
                    : { marginLeft: "5px" }
                }
                onTouchStart={() => {
                  setFocusSpeed(true);
                  if (!clickBottomSheetList) {
                    setContentDrag(false);
                  }
                }}
                onTouchEnd={() => {
                  setFocusSpeed(false);
                  setContentDrag(true);
                }}
              >
                {filterSpeedName}
              </button>}
              {<button className={filterTypeName === "충전타입" ? "buttonN" : "buttonY"} type="button" onClick={filterTypeStatus}
                style={filterTypeName === "충전타입"
                  ? focusType
                    ? { filter: "brightness(90%)", marginLeft: "5px", backgroundColor: "#fff", color: "#000" }
                    : { marginLeft: "5px", backgroundColor: "#fff", color: "#000" }
                  : focusType
                    ? { filter: "brightness(90%)", marginLeft: "5px" }
                    : { marginLeft: "5px" }
                }
                onTouchStart={() => {
                  setFocusType(true);
                  if (!clickBottomSheetList) {
                    setContentDrag(false);
                  }
                }}
                onTouchEnd={() => {
                  setFocusType(false);
                  setContentDrag(true);
                }}
              >
                {filterTypeName}
              </button>}
              {<button className={filterOperatorName === "운영기관" ? "buttonN" : "buttonY"} type="button" onClick={filterOperatorStatus}
                style={filterOperatorName === "운영기관"
                  ? focusCpo
                    ? { filter: "brightness(90%)", marginLeft: "5px", backgroundColor: "#fff", color: "#000" }
                    : { marginLeft: "5px", backgroundColor: "#fff", color: "#000" }
                  : focusCpo
                    ? { filter: "brightness(90%)", marginLeft: "5px" }
                    : { marginLeft: "5px" }
                }
                onTouchStart={() => {
                  setFocusCpo(true);
                  if (!clickBottomSheetList) {
                    setContentDrag(false);
                  }
                }}
                onTouchEnd={() => {
                  setFocusCpo(false);
                  setContentDrag(true);
                }}
              >
                {filterOperatorName}
              </button>}
              {<button type="button" onClick={filterPncStatus}
                style={filterPncValue
                  ? focusPnc
                    ? { filter: "brightness(90%)", border: '1px solid #6E747A', fontFamily: "TMOBI500", marginLeft: "5px" }
                    : { border: '1px solid #6E747A', fontFamily: "TMOBI500", marginLeft: "5px" }
                  : focusPnc
                    ? { filter: "brightness(90%)", backgroundColor: "#fff", color: "#000", marginLeft: "5px" }
                    : { backgroundColor: "#fff", color: "#000", marginLeft: "5px" }
                }
                onTouchStart={() => {
                  setFocusPnc(true);
                  if (!clickBottomSheetList) {
                    setContentDrag(false);
                  }
                }}
                onTouchEnd={() => {
                  setFocusPnc(false);
                  setContentDrag(true);
                }}
              >
                오토차지
              </button>}
              {<button type="button" onClick={filterAvailableStatus}
                style={filterAvailableValue === "CHARGING_STANDBY"
                  ? focusUse
                    ? { filter: "brightness(90%)", border: '1px solid #6E747A', fontFamily: "TMOBI500", marginLeft: "5px" }
                    : { border: '1px solid #6E747A', fontFamily: "TMOBI500", marginLeft: "5px" }
                  : focusUse
                    ? { filter: "brightness(90%)", backgroundColor: "#fff", color: "#000", marginLeft: "5px" }
                    : { backgroundColor: "#fff", color: "#000", marginLeft: "5px" }
                }
                onTouchStart={() => {
                  setFocusUse(true);
                  if (!clickBottomSheetList) {
                    setContentDrag(false);
                  }
                }}
                onTouchEnd={() => {
                  setFocusUse(false);
                  setContentDrag(true);
                }}
              >
                이용가능
              </button>}
            </div>
          </div>
          <div style={{ boxShadow: "0px 10px 10px -1px #fff", width: "100%", padding: "10px 0", position: "fixed", zIndex: "9", height: "50px" }} />

          <figure style={{ padding: "0 20px", height: "150px", top: "65px", position: "relative", width: "100%" }}>
            {
              mainBannerList.length > 1 ?
              <Slider {...bannerSet}>
                {
                  mainBannerList.map((item, index) => {
                    return(
                      <div className={bannerClassName(item?.bannerCode)} onClick={() => goBannerInfo(item?.bannerCode)}>
                        <div style={{ lineHeight: "1.55" }}>
                          <img src={require(`%/images/banner/${item?.bannerImage}`).default} alt="" id="banner" style={{ float: "right", top: "3px", position: "relative" }} />
                          <span className="banner-header">{bannerHeadName(item?.bannerCode)}</span> <br />
                          <span className="banner-content">{bannerName(item?.bannerCode)}</span>
                        </div>
                      </div>
                    )
                  })
                }
              </Slider>   
              :
              <div className={bannerClassName(mainBannerList[0]?.bannerCode)} onClick={() => goBannerInfo(mainBannerList[0]?.bannerCode)} style={{borderRadius:"5px"}}>
                <div style={{ lineHeight: "1.55" }}>
                  <img src={mainBannerList.length ===0 ? null : require(`%/images/banner/${mainBannerList[0]?.bannerImage}`).default} alt="" id="banner" style={{ float: "right", top: "3px", position: "relative" }} />
                  <span className="banner-header">{bannerHeadName(mainBannerList[0]?.bannerCode)}</span> <br />
                  <span className="banner-content">{bannerName(mainBannerList[0]?.bannerCode)}</span>
                </div>
              </div>
            }
          </figure>

          {/* <img src={require(`%/images/bottomsheet-banner.svg`).default} alt="" style={{width:"100%", height:"100px", padding:"0 20px", marginTop: "60px"}} id="banner" onClick={() => props.history.replace('/mainInfo')}/>
            <img src={require(`%/images/bottomsheet-banner1.svg`).default} alt="" style={{width:"100%", height:"100px", padding:"0 20px", marginTop: "60px"}} id="banner" onClick={() => props.history.replace('/mainInfo')}/> */}

          <div className="list-box"
          //  style={{ marginBottom: `${margin}px` }}
          >
            {stationInfo[0]?.distance <= 10000 && isNull === false ? (
              stationInfo
                .filter((x) => x.distance <= 10000)
                .map((x, i) => {
                  // 시트안에 임시 충전소 데이터들 추가
                  return (
                    <ul
                      key={x.pkey}
                      ref={(el) => (contentRef.current[i] = el)}
                      style={focusStationBtn && x.pkey === focusStation ? { background: "var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))", filter: "brightness(90%)" } : {}}
                    >
                      <div className="list-item">
                        <li key={x.pkey}
                        //  ref={refs[x.pkey]}
                        >
                          <div
                            style={{ display: "flex", width: "100%" }}
                            onClick={() => {
                              store.dispatch(appActions.setPoiId(x.poi_id));
                              store.dispatch(appActions.setPkey(x.pkey));
                              store.dispatch(appActions.setNavX(x.center_x));
                              store.dispatch(appActions.setNavY(x.center_y));
                              goPoiDetail('poi');
                            }}
                            onTouchStart={() => {
                              setFocusStation(x.pkey);
                              setFocusStationBtn(true);
                            }}
                            onTouchEnd={() => {
                              setFocusStation("");
                              setFocusStationBtn(false);
                            }}
                          >
                            {/* <div className="inner-list-item">
                                {x.ev_chargers[0]?.operator_id !== "PW" &&
                                  x.ev_chargers[0]?.operator_id !== "PI" &&
                                  x.ev_chargers[0]?.operator_id !== "KP" &&
                                  x.ev_chargers[0]?.operator_id !== "SF" &&
                                  x.ev_chargers[0]?.operator_id !== "EV" &&
                                  x.ev_chargers[0]?.operator_id !== "GN" &&
                                  x.ev_chargers[0]?.operator_id !== "ME" &&
                                  x.ev_chargers[0]?.operator_id !== "HE" &&
                                  x.ev_chargers[0]?.operator_id !== "HM" &&
                                  x.ev_chargers[0]?.operator_id !== "KL" &&
                                  x.ev_chargers[0]?.operator_id !== "JE" &&
                                  x.ev_chargers[0]?.operator_id !== "CV" &&
                                  x.ev_chargers[0]?.operator_id !== "EP" &&
                                  x.ev_chargers[0]?.operator_id !== "ST" &&
                                  x.ev_chargers[0]?.operator_id !== "KE" &&
                                  x.ev_chargers[0]?.operator_id !== "EZ" &&
                                  x.ev_chargers[0]?.operator_id !== "LH" &&
                                  x.ev_chargers[0]?.operator_id !== "SE" &&
                                  x.ev_chargers[0]?.operator_id !== "SN" &&
                                  x.ev_chargers[0]?.operator_id !== "IN" &&
                                  x.ev_chargers[0]?.operator_id !== "NT" ? (
                                  <img
                                    style={{ width: "16px", height: "16px", marginLeft: "5px", marginTop: "5px" }}
                                    src={
                                      require("%/images/ic-poi-evstation.svg")
                                        .default
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={
                                      require(`%/images/ic-list-evstation-${x.ev_chargers[0]?.operator_id.toLowerCase()}@3x.png`)
                                        .default
                                    }
                                    alt=""
                                  />
                                )}
                              </div> */}

                            {/* <img src={require("%/images/ic-list-evstation-01.png").default} alt="" /> */}
                            <div>
                              <h4>{Number(window.innerWidth) > 450 ? x.org_name : Number(window.innerWidth) <= 340 ? utils.textLengthOverCut(x.org_name, 10, '...') : utils.textLengthOverCut(x.org_name, 21, '...')}</h4>
                              {/* <h4>{x.org_name}</h4> */}
                              <div className="result">
                                {renderChargerInfo(x, x.ev_charger_count_info[2], "type7", "SUPER_FAST")}
                                {renderChargerInfo(x, x.ev_charger_count_info[1], "type3", "FAST")}
                                {renderChargerInfo(x, x.ev_charger_count_info[0], "type5", "SLOW")}

                                <div className="addr">
                                  {Math.floor(x.distance) >= 1000
                                    ? Math.round(x.distance / 100) / 10 + "km"
                                    : Math.floor(x.distance) + "m"}{" "}
                                  {/* &middot; {Number(window.innerWidth) <= 340 ? utils.textLengthOverCut(x.full_address_jibun, 9, '...') : x.full_address_jibun} */}
                                </div>
                              </div>

                              <div className="station-info-group">
                                {x.tmap_private_ev_yn === "Y" ? <div className="info" style={{ backgroundColor: "#E6F1FF", color: "#0052D1" }}>티맵결제</div> : <></>}
                                <div className="info">
                                  {
                                    cpoList.includes(x.ev_chargers[0]?.operator_id)
                                      ?
                                      <img
                                        src={
                                          require(`%/images/cpo/ic-list-evstation-${x.ev_chargers[0]?.operator_id.toLowerCase()}@3x.png`)
                                            .default
                                        }
                                        alt=""
                                      />
                                      :
                                      <img
                                        src={
                                          require("%/images/cpo/ic-poi-evstation.svg")
                                            .default
                                        }
                                        alt=""
                                        className="cpo-img"
                                        style={{ width: "14px", marginRight: "3px" }}
                                      />
                                  }
                                  {x.ev_chargers[0]?.operator_name}
                                </div>
                                {/* {x.tmap_private_ev_yn === "Y" && (x.ev_chargers[0]?.operator_id === "EV" || x.ev_chargers[0]?.operator_id === "ME") ?
                                  <div className="info">
                                    탭탭차지
                                  </div>
                                  :
                                  <></>
                                } */}
                                {x.pncIncludeYn ?
                                  <div className="info">
                                    오토차지
                                  </div>
                                  :
                                  <></>}
                              </div>

                            </div>

                          </div>

                          {Math.floor(x.distance) < 1000 && refreshStatus === true && x.tmap_private_ev_yn === "Y" && (x.ev_chargers[0]?.operator_id === "EV" || x.ev_chargers[0]?.operator_id === "ME"|| x.ev_chargers[0]?.operator_id === "PC")
                            ?
                            <>
                              <div>
                                {/* {Math.floor(x.distance) < 1000 &&
                                    x.tmap_private_ev_yn === "Y" &&
                                    refreshStatus === true ? (
                                    // x.ev_chargers[0]?.operator_id === "PI" ||
                                    x.ev_chargers[0]?.operator_id === "EV" ||
                                      // x.ev_chargers[0]?.operator_id === "KP" ||
                                      x.ev_chargers[0]?.operator_id === "ME" ? ( */}
                                <span
                                  className={
                                    // lineSelected[i]
                                    //   ? "status type4"
                                    //   : 
                                    "status type2"
                                  }
                                  onClick={() => {
                                    mixpanel("evcharge:/evcharge/main:tap.taptapcharge");
                                    logbox("/evcharge/main", "tap.taptapcharge");

                                    // props.history.push("/chargerDetail");
                                    store.dispatch(
                                      appActions.setStationInfo(x)
                                    );

                                    goStartEasyPayChagingView(x.poi_id, x.center_wgs84_lon, x.center_wgs84_lat);

                                  }}
                                  onTouchStart={() => {
                                    setFocusTapBtn(true);
                                    setFocusStation(x.pkey);
                                  }}
                                  onTouchEnd={() => {
                                    setFocusTapBtn(false);
                                    setFocusStation("");
                                  }}
                                  style={x.pkey === focusStation && focusTapBtn ? { filter: "brightness(90%)" } : {}}
                                >
                                  탭탭차지
                                </span>
                                {/* ) : (
                                      <span
                                        className={
                                          // lineSelected[i]
                                          //   ? "status type4"
                                          //   : 
                                            "status type2"
                                        }
                                        onClick={() => {
                                          mixpanel("evcharge:/evcharge/main:tap.chargestart");
                                          logbox("/evcharge/main","tap.chargestart");
                                          goStartQrChagingView();
                                        }}
                                      >
                                        충전시작
                                      </span>
                                    )
                                  ) : (
                                    <span className="status type3">충전시작</span>
                                  )} */}
                              </div>
                            </>
                            :
                            (
                              (Math.floor(x.distance) >= 1000 || refreshStatus === false) && x.tmap_private_ev_yn === "Y" && (x.ev_chargers[0]?.operator_id === "EV" || x.ev_chargers[0]?.operator_id === "ME" || x.ev_chargers[0]?.operator_id === "PC") ?
                                <span className="status type2" style={{ backgroundColor: "#F8F9FA", border: "none", color: "#BEC5CC" }}>탭탭차지</span>
                                :
                                <></>
                            )
                          }
                          {/* </dd> */}
                        </li>
                      </div>
                    </ul>
                  );
                })
            ) : (
              <div className="nocharge">
                <div className="normal">근처에 충전소가 없습니다.</div>
                <div className="grey">다른 위치에서 탐색해보세요.</div>
              </div>
            )}

          </div>
        </div>
      </BottomSheet >

      <Sheet //필터 시트
        isOpen={filterBottomSheet} //바텀시트 열기
        onClose={() => setFilterBottomSheet(false)}
        snapPoints={
          filterSpeed ? [300, 0] : filterType ? [400, 0] : [400, 0]
        } // 높이 조절 기능 최대값 두개 설정해서 최상단 최하단 지정
        disableDrag={true}
      >
        <Sheet.Container style={{ borderRadius: "15px", boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.20)" }}>
          {/* <div className="filter-top">
              <h3 className="filter-title">{filterTitle}</h3>
            </div> */}

          <div style={{ boxShadow: "0px 10px 10px -1px #fff", width: "100%", padding: "10px 0", position: "relative", zIndex: "9" }}></div>
          <Sheet.Content style={{ borderRadius: "15px" }}>
            {filterSpeed ? (
              <div className="charge-filter">
                <div className="filter-list">
                  <ul>
                    {filterSpeedData
                      .map((item, index) => {
                        return (
                          <li onClick={() => {
                            filterSpeedExtra(item.key, item.extraStatus);
                          }}>
                            <div className="filter-checkbox">
                              <input type="checkbox" id={item.couponIdx} checked={item.extraStatus === "Y" ? true : false} onChange={() => filterSpeedExtra(item.key, item.extraStatus)} />
                              <label htmlFor={item.couponIdx}>
                              </label>
                              <h3 className="list-title">
                                {item.key}
                              </h3>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
            {filterType ? (
              <div className="charge-filter">
                <div className="filter-list">
                  <ul>
                    {filterTypeData
                      .map((item, index) => {
                        return (
                          <li onClick={() => {
                            filterTypeExtra(item.key, item.extraStatus);
                          }}>
                            <div className="filter-checkbox">
                              <input type="checkbox" id={item.couponIdx} checked={item.extraStatus === "Y" ? true : false} onChange={() => filterTypeExtra(item.key, item.extraStatus)} />
                              <label htmlFor={item.couponIdx}>
                              </label>
                              <h3 className="list-title">
                                {item.key}
                              </h3>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
            {filterOperator ? (
              <div className="charge-filter">
                <div className="filter-list">
                  <ul>
                    {filterOperatorData
                      .map((item, index) => {
                        return (
                          <li onClick={() => {
                            filterOperatorExtra(item.cpoName, item.extraStatus);
                          }}>
                            <img
                              className="filter-img"
                              src={
                                require(`%/images/cpo/ic-list-evstation-${(item.cpoCode).toLowerCase()}@3x.png`)
                                  .default
                              }
                              alt=""
                            />
                            <div className="filter-checkbox" style={{ padding: "0 0 0 30px" }}>
                              <input type="checkbox" id={item.couponIdx} checked={item.extraStatus === "Y" ? true : false} onChange={() => filterOperatorExtra(item.cpoName, item.extraStatus)} />
                              <label htmlFor={item.couponIdx}>
                              </label>
                              <h3 className="list-title">
                                {item.cpoName}
                              </h3>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Sheet.Content>
          {filterSpeed ? (
            <div className="filter-button">
              <div className="no-button-group">
                <button
                  type="button"
                  onClick={() => {
                    filterSpeedReset();
                  }}
                  onTouchStart={() => setPressed({ ...pressed, focusReset: true, })}
                  onTouchEnd={() => setPressed({ ...pressed, focusReset: false })}
                  style={pressed.focusReset ? { filter: "brightness(90%)" } : {}}
                >
                  초기화
                </button>
              </div>
              <div className="yes-button-group">
                <button
                  type="button"
                  onClick={() => {
                    filterSpeedApply();
                    // setLonZoomLevel(13);
                    // setLatZoomLevel(13);
                    // setDefaultZoom(false);
                    if (refreshCurrent) {
                      setRefreshStatus(true);
                    } else {
                      setRefreshStatus(false);
                    }
                  }}
                  onTouchStart={() => setPressed({ ...pressed, focusFilter: true, })}
                  onTouchEnd={() => setPressed({ ...pressed, focusFilter: false })}
                  style={pressed.focusFilter ? { filter: "brightness(90%)" } : {}}
                >
                  필터 적용
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {filterType ? (
            <div className="filter-button">
              <div className="no-button-group">
                <button
                  type="button"
                  onClick={() => {
                    filterTypeReset();
                  }}
                  onTouchStart={() => setPressed({ ...pressed, focusReset: true, })}
                  onTouchEnd={() => setPressed({ ...pressed, focusReset: false })}
                  style={pressed.focusReset ? { filter: "brightness(90%)" } : {}}
                >
                  초기화
                </button>
              </div>
              <div className="yes-button-group">
                <button
                  type="button"
                  onClick={() => {
                    filterTypeApply();
                    // setLonZoomLevel(13);
                    // setLatZoomLevel(13);
                    // setDefaultZoom(false);
                    if (refreshCurrent) {
                      setRefreshStatus(true);
                    } else {
                      setRefreshStatus(false);
                    }
                  }}
                  onTouchStart={() => setPressed({ ...pressed, focusFilter: true, })}
                  onTouchEnd={() => setPressed({ ...pressed, focusFilter: false })}
                  style={pressed.focusFilter ? { filter: "brightness(90%)" } : {}}
                >
                  필터 적용
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {filterOperator ? (
            <div className="filter-button">
              <div className="no-button-group">
                <button
                  type="button"
                  onClick={() => {
                    filterOperatorReset();
                  }}
                  onTouchStart={() => setPressed({ ...pressed, focusReset: true, })}
                  onTouchEnd={() => setPressed({ ...pressed, focusReset: false })}
                  style={pressed.focusReset ? { filter: "brightness(90%)" } : {}}
                >
                  초기화
                </button>
              </div>
              <div className="yes-button-group">
                <button
                  type="button"
                  onClick={() => {
                    filterOperatorApply();
                    // setLonZoomLevel(13);
                    // setLatZoomLevel(13);
                    // setDefaultZoom(false);
                    if (refreshCurrent) {
                      setRefreshStatus(true);
                    } else {
                      setRefreshStatus(false);
                    }
                  }}
                  onTouchStart={() => setPressed({ ...pressed, focusFilter: true, })}
                  onTouchEnd={() => setPressed({ ...pressed, focusFilter: false })}
                  style={pressed.focusFilter ? { filter: "brightness(90%)" } : {}}
                >
                  필터 적용
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Sheet.Container>
        <Sheet.Backdrop
          style={{ opacity: "0.1" }}
          onTap={() => {
            setFilterBottomSheet(false);
          }}
        />
      </Sheet>

      <Sheet //마커 선택 시트
        isOpen={stationSheet} //바텀시트 열기
        onClose={() => setStationSheet(false)}
        snapPoints={[240, 0]} // 높이 조절 기능 최대값 두개 설정해서 최상단 최하단 지정
        disableDrag={true}
      >
        <Sheet.Container style={{ boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.20)", borderTopRightRadius: "16px", borderTopLeftRadius: "16px" }}>
          {/* <div className="filter-top">
              <h3 className="filter-title">{filterTitle}</h3>
            </div> */}

          <div className="util-group">
            {currentPositionMode === "1" ? (
              <button //detail 현재위치 버튼
                className="location-button"
                style={{
                  WebkitAppearance: "none",
                  position: "absolute",
                  right: "7px",
                  top: "-65px",
                  zIndex: "5",
                }}
                onClick={() => {
                  //setCurrentPositionMode("1");
                  setRefreshCurrent(true);
                  tmapInterface.getCurrentPosition(currentPositionRefresh);

                  mixpanel("evcharge:/evcharge/main:tap.location");
                  logbox("/evcharge/main", "tap.location");

                  // getLocation();
                  //getStationInfo();
                }}
                onDoubleClick={() => {
                  setCurrentPositionMode("2");
                  setRefreshCurrent(true);
                  tmapInterface.getCurrentPosition(currentPositionSecondMode);
                  // getLocation();
                  //getStationInfo();
                }}
              >
                <img style={{ position: "relative", width: "52px",top: "3px" }}
                  src={require("%/images/common/btn-position.png").default}
                  alt=""
                />
              </button>
            ) : currentPositionMode === "2" ? (
              <button //현재위치 버튼 임시 생성
                className="location-button"
                style={{
                  WebkitAppearance: "none",
                  position: "absolute",
                  right: "7px",
                  top: "-65px",
                  zIndex: "5",
                }}
                onClick={() => {
                  setCurrentPositionMode("1");
                  setRefreshCurrent(true);
                  tmapInterface.getCurrentPosition(currentPositionRefresh);
                  // getLocation();
                  // getStationInfo();
                }}
              >
                <img style={{ position: "relative", width: "52px",top: "3px" }}
                  src={
                    require("%/images/common/btn-position-direction-on.png")
                      .default
                  }
                  alt=""
                />
              </button>
            ) : (
              <></>
            )}

            {refreshButton ? ( // 재검색 버튼
              <button
                className="refresh-button"
                style={
                  focusCurrent ? {
                    WebkitAppearance: "none",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)", // %조정
                    background: "white",
                    zIndex: "5",
                    filter: "brightness(90%)",
                    top: "-60px"
                  }
                    :
                    {
                      WebkitAppearance: "none",
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)", // %조정
                      background: "white",
                      zIndex: "5",
                      top: "-60px"
                    }
                }
                onClick={() => {
                  if (currentButton == false) {
                    currentPositionLon = onDragPosition._lng;
                    currentPositionLat = onDragPosition._lat;
                  }
                  setCurrentButton(false);
                  // if (currentPositionLon == currentPointLon) {
                  //   setCurrentRefresh(true);
                  // }
                  setTouchStart(false);
                  // setZoomLevel(13);
                  // setLonZoomLevel(13);
                  // setLatZoomLevel(13);
                  // setDefaultZoom(false);
                  // setDragging(false);
                  getStationInfo();
                  if (currentPositionLon) {
                    EvDeliveryStatus();
                  }
                  setRefreshButton(false);
                  setCurrentPositionMode("1");
                  if (refreshCurrent) {
                    setRefreshStatus(true);
                  } else {
                    setRefreshStatus(false);
                  }
                }}
                onTouchStart={() => setFocusCurrent(true)} onTouchEnd={() => setFocusCurrent(false)}
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  <span style={{ color: "#0064ff" }}>
                    {Number(window.innerWidth) > 370
                      ?
                      <>
                        {refreshName.length <= 11
                          ? refreshName
                          : utils.textLengthOverCut(refreshName, 11, "···")
                        }
                      </>
                      :
                      <>
                        {Number(window.innerWidth) < 340 ?
                          <>
                            {refreshName.length <= 3
                              ? refreshName
                              : utils.textLengthOverCut(refreshName, 3, "···")
                            }
                          </>
                          :
                          <>
                            {refreshName.length <= 8
                              ? refreshName
                              : utils.textLengthOverCut(refreshName, 8, "···")
                            }
                          </>
                        }
                      </>
                    }
                  </span>
                  에서 재검색
                </span>
              </button>
            ) : (
              <></>
            )}
          </div>

          <Sheet.Content>
            {
              focusStationInfo
                .map((x, index) => {
                  return (
                    <>
                      {
                        x.selected ?
                          (
                            <div className="main-bottomSheet-detail">
                              <div className="list-box"
                                onTouchStart={() => setFocusDetailList(true)}
                                onTouchEnd={() => setFocusDetailList(false)}
                                style={focusDetailList && !focusDetail ? { background: "var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))" } : {}}
                                onClick={() => {
                                  // if(!focusDetail) {
                                  store.dispatch(appActions.setPoiId(x.poi_id));
                                  store.dispatch(appActions.setPkey(x.pkey));
                                  store.dispatch(appActions.setNavX(x.center_x));
                                  store.dispatch(appActions.setNavY(x.center_y));
                                  goPoiDetail('detail');
                                  // } 
                                }}
                              >
                                <div className="list-item">
                                  <div
                                  // onClick={() => {
                                  //   store.dispatch(appActions.setPoiId(x.poi_id));
                                  //   store.dispatch(appActions.setPkey(x.pkey));
                                  //   store.dispatch(appActions.setNavX(x.center_x));
                                  //   store.dispatch(appActions.setNavY(x.center_y));
                                  //   goPoiDetail();
                                  // }}
                                  >
                                    <div>
                                      <h4>{Number(window.innerWidth) > 450 ? x.org_name : Number(window.innerWidth) <= 340 ? utils.textLengthOverCut(x.org_name, 10, '...') : utils.textLengthOverCut(x.org_name, 22, '...')}</h4>
                                      <p className="result">
                                        {renderChargerInfo(x, x.ev_charger_count_info[2], "type7", "SUPER_FAST")}
                                        {renderChargerInfo(x, x.ev_charger_count_info[1], "type3", "FAST")}
                                        {renderChargerInfo(x, x.ev_charger_count_info[0], "type5", "SLOW")}
                                      </p>
                                      <p className="addr">
                                        {Math.floor(x.distance) >= 1000
                                          ? Math.round(x.distance / 100) / 10 + "km"
                                          : Math.floor(x.distance) + "m"}{" "}
                                      </p>
                                      <div className="station-info-group">
                                        {x.tmap_private_ev_yn === "Y" ? <div className="info" style={{ backgroundColor: "#E6F1FF", color: "#0052D1" }}>티맵결제</div> : <></>}
                                        <div className="info">
                                          {
                                            cpoList.includes(x.ev_chargers[0]?.operator_id)
                                              ?
                                              <img
                                                src={
                                                  require(`%/images/cpo/ic-list-evstation-${x.ev_chargers[0]?.operator_id.toLowerCase()}@3x.png`)
                                                    .default
                                                }
                                                alt=""
                                              />
                                              :
                                              <img
                                                src={
                                                  require("%/images/cpo/ic-poi-evstation.svg")
                                                    .default
                                                }
                                                alt=""
                                                className="cpo-img"
                                                style={{ width: "14px", marginRight: "3px" }}
                                              />
                                          }
                                          {x.ev_chargers[0]?.operator_name}
                                        </div>
                                        {/* {x.tmap_private_ev_yn === "Y" && (x.ev_chargers[0]?.operator_id === "EV" || x.ev_chargers[0]?.operator_id === "ME") ?
                                          <div className="info">
                                            탭탭차지
                                          </div>
                                          :
                                          <></>
                                        } */}
                                        {x.pncIncludeYn ?
                                          <div className="info">
                                            오토차지
                                          </div>
                                          :
                                          <></>}
                                      </div>
                                      <button className="poidetail" onClick={() => {
                                        // store.dispatch(appActions.setPoiId(x.poi_id));
                                        // store.dispatch(appActions.setPkey(x.pkey));
                                        // store.dispatch(appActions.setNavX(x.center_x));
                                        // store.dispatch(appActions.setNavY(x.center_y));
                                        // goPoiDetail('detail');
                                      }}
                                        style={focusDetail ? { filter: "brightness(90%)", zIndex: "9" } : { zIndex: "9" }} onTouchStart={() => { setFocusDetail(true); setFocusDetailList(false) }} onTouchEnd={() => setFocusDetail(false)}
                                      >자세히</button>

                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="button-group-detail">
                                <button type="button" onClick={() => {
                                  Math.floor(x.distance) < 1000 && refreshStatus && x.tmap_private_ev_yn === "Y" && (x.ev_chargers[0]?.operator_id === "EV" || x.ev_chargers[0]?.operator_id === "ME" || x.ev_chargers[0]?.operator_id === "PC") ?
                                    goStartEasyPayChagingView(x.poi_id, x.center_wgs84_lon, x.center_wgs84_lat)
                                    :
                                    goStartQrChagingView(x.ev_chargers[0]?.operator_id)
                                }}
                                  onTouchStart={() => setFocusCharging(true)} onTouchEnd={() => setFocusCharging(false)}
                                  style={focusCharging ? { filter: "brightness(90%)", backgroundColor: "#0064ff", color: "#fff" } : { backgroundColor: "#0064ff", color: "#fff" }}
                                >
                                  <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Lottie animationData={QrAnimation} style={Qrstyle} />
                                    <span style={{ fontFamily: "TMOBI500" }}>충전 시작하기</span>
                                  </div>
                                </button>
                              </div>
                            </div>
                          )
                          :
                          <></>
                      }
                    </>
                  )
                })
            }
          </Sheet.Content>

        </Sheet.Container>
        {/* <Sheet.Backdrop
            style={{ opacity: "0", backgroundColor:"" }}
            onTap={() => {
              onClickCustomMarker("")
              setBottomSheet(true);
              setStationSheet(false);
            }}
          /> */}
      </Sheet>
      {/* </div> */}
    </>
  );
}
  //}
}

export { Main };
