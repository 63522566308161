/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";

import { tmapInterface } from "../../libs/tmap-interface";
import { globalVars } from "../../libs/global-vars";
import { utils } from "../../libs/utils";

// Mok APi
//import { tmapApi } from '../../libs/tmap-api';

// 헤더
import { Header } from "../../components/Layout";
import { HandleBrowserBackButton } from "../../components/Layout";

import { appManager } from "../../managers";

// Util
import { isEmpty, isBoolean } from "lodash-es";
import { get } from "lodash-es";

// AxioS
import axios, { AxiosError } from "axios";
import Const from "../../store/ev";
import { appActions, store } from "../../store";
import { AccessKey } from "../../components/DebugTools/Panel/Items";
import { modal } from "../../components/GlobalModal";

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getUser, postUser } from "../../apis/user";
import { getUserStatus } from "../../apis/status";
import { postMomenty, postPkeyMomenty } from "../../apis/momenty";
import { getPayMeans } from "../../apis/paymeans";
import { getDeliveryWeb } from "../../apis/delivery";

// 전역
let userData: any = {}; // 회원정보조회(단건)
let cardInfo: any = {};	// 카드정보
let timer: Nullable<number> = null; //타이머

//const [inited, setInited] = useState(true)

// 팝업
// const [evPopVisble    , setEvPopVisble]   = useState(false);   // 유종변경 선택 팝업 Popup (숨김)

// // 상태값

// const [remainPoint    , setRemainPoint]   = useState("");       // 쿠폰 잔액 :userData.remain_point
// const [couponName   , setCouponName]   = useState("");       // 쿠폰 잔액 :userData.coupon_name

// const [couponFirst    , setCouponFirst] 	= useState(false);   // 쿠폰 우선사용 여부 : userData.coupon_first_yn

// const [payType        , setPayType]       = useState('N');     // 결제수단 : TODO
// const [payTypeNm      , setPayTypeNm]     = useState('');     // 결제수단 명 : TODO
// const [payImageUrl 	, setPayImageUrl]   = useState('');     // 결제수단 이미지 CI

// const [isCard 	, setIsCard]   = useState('NoFocus');
// const [payImageBg 	, setPayImageBg]   = useState('transparent');     // 결제수단 이미지 CI
// const [bselectCard    , setBselectCard]   = useState('');
// const [Nonpay    , setNonpay]   = useState(false);
// const [NowCharging    , setNowCharging]   = useState(false);
// const [disabledYn, setDisabledYn] = useState(true); // disabled 여부

// const [startButton, setStartButton] = useState(false);
let serviceType: any = {};
let serviceId: any = {};
let activeSession = 0;
let pageId: any = {};

let scrollCache = 0;
/**-------------------------------------------------------------
1.2.1.3 메인 진입 전 화면
-------------------------------------------------------------**/

function MainRouter(props: RouteComponentProps<{ id: string }>) {

  //결제수단
  const [paySuccess, setPaySuccess] = useState(false);

  const [deepLinkPay, setDeepLinkPay] = useState(false);
  const [deepLinkPaySuccess, setDeepLinkPaySuccess] = useState(false);

  const [accessKeyPaySuccess, setAccessKeyPaySuccess] = useState(false);
  const [accessKeySuccess, setAccessKeySuccess] = useState(false);

  const [payType, setPayType] = useState("N"); // 결제수단 : TODO
  const [payTypeNm, setPayTypeNm] = useState(""); // 결제수단 명 : TODO
  const [payImageUrl, setPayImageUrl] = useState(""); // 결제수단 이미지 CI
  const [payKind, setPayKind] = useState(""); // 결제수단 종류

  const [isCard, setIsCard] = useState("NoFocus");
  const [payImageBg, setPayImageBg] = useState("transparent"); // 결제수단 이미지 CI
  const [bselectCard, setBselectCard] = useState("");
  const [Nonpay, setNonpay] = useState(false);
  const [NowCharging, setNowCharging] = useState(false);

  const [userSetting, setUserSetting] = useState("");
  const [userSettingSuccess, setUserSettingSuccess] = useState(false);

  const [nonDtg, setNonDtg] = useState(false);

  serviceType = (globalVars.queryData.extra?.serviceType)?.toLowerCase() || (globalVars.queryData.extra?.servicetype)?.toLowerCase();
  serviceId = (globalVars.queryData.extra?.serviceId)?.toLowerCase() || (globalVars.queryData.extra?.serviceid)?.toLowerCase();
  pageId = (globalVars.queryData.pageId)?.toLowerCase() || (globalVars.queryData.pageid)?.toLowerCase();

  const history = useHistory();

  const localStorageItem = window.localStorage.getItem("multiCouplerData");
	const multiCouplerData = localStorageItem !== null ? JSON.parse(localStorageItem) : [];

  function euk(value: string) {
    store.dispatch(appActions.setEuk(value));
  }

  function getUserSetting(value: string) {
    setUserSetting(value);
    setUserSettingSuccess(true);
  }

  useEffect(() => {
    globalVars.history = history;
    
    if (globalVars.isIOS) {
      tmapInterface.getUserSetting(getUserSetting);
    } else {
      setUserSetting(tmapInterface.getUserSetting());
      setUserSettingSuccess(true);
    }

    if (multiCouplerData.filter((item) => item.expire > Date.now()).length == 0) {
      window.localStorage.removeItem("multiCouplerData");
    } else if (multiCouplerData.filter((item) => item.expire > Date.now()).length > 0) {
      const filterDeleteAuth = multiCouplerData.filter((item) => item.expire > Date.now());
      window.localStorage.setItem('multiCouplerData', JSON.stringify(filterDeleteAuth));
    }
  }, [])

  // useEffect(() => {
  //   if (userSettingSuccess) {
  //     if (
  //       userSetting === "rtg" &&
  //       window.location.href !== "https://evcharge-dtg.tmobiweb.com/mapTest"
  //     ) {
  //       window.location.href =
  //         "https://evcharge-dtg.tmobiweb.com/mapTest?ak=" + globalVars.accessKey;
  //     } else {
  //       setNonDtg(true);
  //     }
  //   }
  // }, [userSettingSuccess]);

  useEffect(() => {
    if (userSettingSuccess) {
      retrieveCardInfo(globalVars.accessKey);

      if (globalVars.isIOS) {
        if (globalVars.appVersion.length === 6 && Number(globalVars.appVersion.substring(0, 3)) >= 9.1) {
          tmapInterface.getEUK(euk);
          // test()
        } else if (globalVars.appVersion.length === 5 && Number(globalVars.appVersion.substring(0, 3)) >= 8.9) {
          tmapInterface.getEUK(euk);
          // test();
        }
      } else if (globalVars.isAndroid) {
        if (Number(globalVars.appVersion.substring(0, 1)) >= 9) {
          store.dispatch(appActions.setEuk(tmapInterface.getAosEUK()));
        } else if (globalVars.appVersion.length === 6 && Number(globalVars.appVersion.substring(0, 1)) == 8 && Number(globalVars.appVersion.substring(0, 3)) >= 8.1) {
          store.dispatch(appActions.setEuk(tmapInterface.getAosEUK()));
          // test()
        } else if (globalVars.appVersion.length === 5 && Number(globalVars.appVersion.substring(0, 1)) == 8 && Number(globalVars.appVersion.substring(0, 3)) >= 8.9) {
          store.dispatch(appActions.setEuk(tmapInterface.getAosEUK()));
          // test();
        }
      }
    }
  }, [userSettingSuccess])

  useEffect(() => {
    if (paySuccess === true) {
      store.dispatch(appActions.setSubSessionId("A01"));

      // ACCESSKEY 가 있으면
      if (globalVars.accessKey) {
        let accessKey = globalVars.accessKey;

        if (Const.DBG_OK) {
          console.log("#2 AccessKey 검증 및 이상없으면 회원정보 리턴");
        }

        getUser()
          .then(function (response: any) {
            //alert("a");
            // 기존회원이므로 회원정보 받기
            if (response.result) {
              if (response.agreements.length !== 0 && response.agreements.some((agreement) =>
                  agreement.agreementType === 2 &&
                  agreement.agreementCode === 1 &&
                  agreement.agreementYn === true
              )) {
                store.dispatch(appActions.setCvcAgree(true));
              }
              userData = response;

              if (Const.DBG_OK) {
                // console.log(JSON.stringify(response.data));
                alert("사용자정보 " + JSON.stringify(userData));
              }
              mainStart();

              // 미등록회원이므로 DB에 등록
            }
          })
          .catch(function (error: any) {
            if (error.response.status === 400 || error.response.data.result === false) {
              // 신규회원등록
              var data = {};

              postUser(data)
                .then(function (response: any) {
                  if (Const.DBG_OK) {
                    // console.log('신규회원등록')
                    alert("신규회원등록 " + JSON.stringify(response.data));
                  }

                  getUser()
                    .then(function (response: any) {
                      if (response.agreements.length !== 0 && response.agreements.some((agreement) =>
                          agreement.agreementType === 2 &&
                          agreement.agreementCode === 1 &&
                          agreement.agreementYn === true
                      )) {
                        store.dispatch(appActions.setCvcAgree(true));
                      }
                      userData = response;

                      mainStart();

                      if (Const.DBG_OK) {
                        // console.log('신규회원정보 조회성공')
                        alert("신규회원 " + JSON.stringify(response.data));
                      }
                    })
                    .catch(function (error: any) {
                      //							alert('신규회원정보 조회실패')
                      console.log(error);
                    });
                })
                .catch(function (error: any) {
                  console.log(error);
                });
            }
          });
      } else {
        if (Const.DBG_OK) {
          alert("ACCESS KEY 없는경우 - 접속 제한 ()");
          //props.history.replace("/notFound");
        }
        props.history.replace("/notFound");
      }
    }
  }, [paySuccess]);

  //사용자 카드조회
  function retrieveCardInfo(accessKey: any) {
    getPayMeans()
      .then(function (response: any) {
        cardInfo = response;

        if (Const.DBG_OK) {
          alert("retrieveCardInfo - 카드정보 \n" + JSON.stringify(cardInfo));
        }

        if (cardInfo.status === 'OK') {
          setPayType('Y');

          setPaySuccess(true);

          if (accessKeySuccess) {
            setAccessKeyPaySuccess(true);
          }

          if (deepLinkPay) {
            setDeepLinkPaySuccess(true);
          }
        } else {
          setPayType('N');

          setPaySuccess(true);

          if (accessKeySuccess) {
            setAccessKeyPaySuccess(true);
          }
        }
      })
      .catch(function (error: any) {
        setPayType("N");

        setPaySuccess(true);

        if (accessKeySuccess) {
          setAccessKeyPaySuccess(true);
        }
      });
  }

  const payMethodPop = () => {
    modal.alert(() => {
        popClosed();
    }, "결제 카드 등록 후</br>충전을 진행하실 수 있습니다.");
  };

  const popClosed = () => {
    if (timer) clearTimeout(timer);
    timer = window.setTimeout(() => goPayChoiceView(), 1);
  };

  const goPayChoiceView = () => {
    store.dispatch(appActions.setResume("Y"));

    tmapInterface.startPaymentActivity(selectCard);

  };

  function selectCard(status: string) {
    if (Const.DBG_OK) {
      alert("selectCard \n " + "status: " + status);
    }

    if (globalVars.isIOS) {
      tmapInterface.getAccessKey(getAccessKey);
    } else {
      globalVars.accessKey = tmapInterface.getAccessKey();

      setAccessKeySuccess(true);
    }

    store.dispatch(appActions.setResumeIdx("1"));

    // if(status === "0"){
    //   setDeepLinkPay(true);
    // } else {
    //   tmapInterface.onBackKeyPressed();
    // }

    // props.history.replace("/main");
  }

  function getAccessKey(value: string) {
    globalVars.accessKey = value

    setAccessKeySuccess(true);
  }

  useEffect(() => {
    if (accessKeySuccess) {
      retrieveCardInfo(globalVars.accessKey)
    }
  }, [accessKeySuccess])

  useEffect(() => {
    if (accessKeyPaySuccess) {
      if (payType == "Y") {
        setDeepLinkPay(true);
      } else {
        tmapInterface.onBackKeyPressed();
      }
    }
  }, [accessKeyPaySuccess])

  useEffect(() => {
    if (deepLinkPay) {
      retrieveCardInfo(globalVars.accessKey);
    }
  }, [deepLinkPay])

  useEffect(() => {
    if (deepLinkPaySuccess) {
      deepLinkRouter();
    }
  }, [deepLinkPaySuccess])

  function deepLinkRouter() {
    if (
      serviceType === "chkstation"
    ) {
      if (serviceId === 'poidetail') {
        store.dispatch(appActions.setSubSessionId("C01"));
        if (payType == "N") {
          // 결제수단이 없는 경우
          // props.history.replace("/main")
          payMethodPop();
        } else {

          const pkey =
            globalVars.queryData.extra?.payLoad?.pkey ||
            globalVars.queryData.extra?.payLoad?.pKey ||
            globalVars.queryData.extra?.payload?.pkey ||
            globalVars.queryData.extra?.payload?.pKey;
          const lat =
            globalVars.queryData.extra?.payLoad?.lat ||
            globalVars.queryData.extra?.payLoad?.Lat ||
            globalVars.queryData.extra?.payload?.lat ||
            globalVars.queryData.extra?.payload?.Lat;
          const lon =
            globalVars.queryData.extra?.payLoad?.lon ||
            globalVars.queryData.extra?.payLoad?.Lon ||
            globalVars.queryData.extra?.payload?.lon ||
            globalVars.queryData.extra?.payload?.Lon;

          var data = {
            lon: lon,
            lat: lat,
            tmapPrivateEvYn: "",
            evPublicType: "",
            chargerSpeed: "",
            evChargeType: "",
            operatorId: "",
            evChargeStatus: "",
            radius: "1000"
          };

          postPkeyMomenty(data, pkey)
            .then(function (response: any) {
              //const result = (response.data.body.docs).filter(data => data['pkey'] === Number(pkey));
              const result = response.body.docs;
              if (
                result[0]?.ev_chargers[0]?.operator_id === "EV" ||
                result[0]?.ev_chargers[0]?.operator_id === "PC" ||
                result[0]?.ev_chargers[0]?.operator_id === "ME"
              ) {
                store.dispatch(appActions.setPoiDetailStatus("Y"));
                store.dispatch(appActions.setStationInfo(result[0]));
                props.history.replace(`/tapCharge/${result[0].poi_id}/${result[0].center_wgs84_lon}/${result[0].center_wgs84_lat}`);
              } else {
                // tmapInterface.startQrCodeScanActivity(QrCodeScan);
                if (activeSession >= 3) {
                  mixpanel("evcharge:/evcharge/main:view.allmultiuse_popup");
                  logbox("/evcharge/main", "view.allmultiuse_popup");
                  modal.alert(() => {
                    mixpanel("evcharge:/evcharge/main:tap.allmultiuse_ok");
                    logbox("/evcharge/main", "tap.allmultiuse_ok");
                    props.history.replace("/main");
                  }, "사용 가능한 3건의 충전을 모두 사용중에 있습니다. <br/>충전 종료 후 다시 이용해주세요.");
                } else {
                  props.history.replace("/poiDetailRouter");
                }
              }
            })
            .catch(function (error: any) {
              if (Const.DBG_OK) {
                alert("충전소 정보 조회실패");
              }
            });
        }
      }
    } else if (
      serviceType === "chkqrcode"
    ) {

      if (payType == "N") {
        // 결제수단이 없는 경우
        // props.history.replace("/main")
        payMethodPop();
        // props.history.replace("/main");
      } else if (activeSession >= 3) {
        mixpanel("evcharge:/evcharge/main:view.allmultiuse_popup");
        logbox("/evcharge/main", "view.allmultiuse_popup");
        modal.alert(() => {
          mixpanel("evcharge:/evcharge/main:tap.allmultiuse_ok");
          logbox("/evcharge/main", "tap.allmultiuse_ok");
          props.history.replace("/main");
        }, "사용 가능한 3건의 충전을 모두 사용중에 있습니다. <br/>충전 종료 후 다시 이용해주세요.");
      } else {
        store.dispatch(appActions.setSubSessionId("A01"));
        store.dispatch(appActions.setQrType("4"));

        const qrcode: any = globalVars.queryData.extra?.payLoad || globalVars.queryData.extra?.payload

        store.dispatch(appActions.setQrCode(qrcode));

        props.history.replace("/qrAuth");
      }
    }
    else if (
      serviceType === "autoqr"
    ) {
      if (payType == "N") {
        // 결제수단이 없는 경우
        // props.history.replace("/main")
        payMethodPop();
      } else {
        if (activeSession >= 3) {
          mixpanel("evcharge:/evcharge/main:view.allmultiuse_popup");
          logbox("/evcharge/main", "view.allmultiuse_popup");
          modal.alert(() => {
            mixpanel("evcharge:/evcharge/main:tap.allmultiuse_ok");
            logbox("/evcharge/main", "tap.allmultiuse_ok");
            props.history.replace("/main");
          }, "사용 가능한 3건의 충전을 모두 사용중에 있습니다. <br/>충전 종료 후 다시 이용해주세요.");
        } else {
          props.history.replace("/poiDetailRouter");
        }
      }
    } else if (
      serviceType === "couponregister" || pageId === "couponregister"
    ) {
      if (payType == "N") {
        // 결제수단이 없는 경우
        // props.history.replace("/main")
        payMethodPop();
      } else {
        //pageid = couponregister 일 때 쿠폰 등록 페이지로 이동
        serviceType = "";
        store.dispatch(appActions.setSubSessionId("A01"));
        props.history.replace(`/couponRegister/${"1"}`);
      }
    }
    else {
      props.history.replace("/main");
    }
  }

  function QrCodeScan(status: string, qrcode: string) {
    // alert('QrCode - ' + status + '\n' + JSON.stringify(qrcode));

    if (Const.DBG_OK) {
      alert("QrCode" + status + " " + JSON.stringify(qrcode));
    }
    //qr코드가 '' 빈값일때 qr인증실패로 이동
    if (status === "COMPLETE") {
      if (!qrcode) {
        props.history.replace("/authFail");
      } else {
        store.dispatch(appActions.setQrCode(qrcode));
        store.dispatch(appActions.setQrType("4"));

        props.history.replace("/qrAuth");
      }
    } else if (status === "CANCEL") {
      mixpanel("evcharge:/evcharge/qrscan:tap.back");
      logbox("/evcharge/qrscan", "tap.back");
    }
  }

  function currentPosition(latitude: string, longitude: string) {
    getDeliveryWeb(latitude, longitude)
      .then((response: any) => {
        const objectHtml = document.createElement('div');
        objectHtml.innerHTML = response;
        // console.log(objectHtml.querySelector('form')?.getAttribute('action'));

        tmapInterface.tChargeDelivery(objectHtml.querySelector('form')?.getAttribute('action') as string);
      })
      .catch((error: AxiosError) => {
        console.log(error);
      });
  }

  // > 1 EV 충전 메뉴 진입 시 T map 회원 인중
  function mainStart() {
    store.dispatch(appActions.setEvPopCloseYn(userData.ev_check_yn));

    if (Const.DBG_OK) {
      // console.log('mainStart')
      alert("mainStart - userData : " + userData.result);
    }

    //alert(JSON.stringify(userData.qr_status));
    // 1.1 회원정보 조회 – DB에 회원키 존재 시
    if (userData.result) {
      //alert("b");
      if (Const.DBG_OK) {
        alert("사용자 정보 \n" + JSON.stringify(userData));
        alert(
          "유종타입 : " + typeof globalVars.fuelType + " " + globalVars.fuelType
        );
      }

      if (
        serviceType === "eventpage"
      ) {
        props.history.replace(`/evPromotion`);
      } else if (
        serviceType === "shinhanpromotion"
      ) {
        props.history.replace(`/shinhanPromotion`);
      } else if (
        serviceType === "evdelivery"
      ) {
        props.history.replace(`/deliveryPromotion`);
      } else if (
        serviceType === "evdeliveryfree"
      ) {
        props.history.replace(`/freeDeliveryPromotion`);
      } else if (
        serviceType === "spharos"
      ) {
        props.history.replace(`/spharosPromotion`);
      } else if (
        serviceType === "repayinfo"
      ) {
        props.history.replace(`/repayInfo`);
      } else if (
        serviceType === "pluglink"
      ) {
        props.history.replace(`/plugLinkPromotion`);
      }
      else if (serviceId === 'destinationcall') {
        store.dispatch(appActions.setSubSessionId("B01"));

        const pkey =
          globalVars.queryData.extra?.payLoad?.pkey ||
          globalVars.queryData.extra?.payLoad?.pKey ||
          globalVars.queryData.extra?.payload?.pkey ||
          globalVars.queryData.extra?.payload?.pKey;
        const lat =
          globalVars.queryData.extra?.payLoad?.lat ||
          globalVars.queryData.extra?.payLoad?.Lat ||
          globalVars.queryData.extra?.payload?.lat ||
          globalVars.queryData.extra?.payload?.Lat;
        const lon =
          globalVars.queryData.extra?.payLoad?.lon ||
          globalVars.queryData.extra?.payLoad?.Lon ||
          globalVars.queryData.extra?.payload?.lon ||
          globalVars.queryData.extra?.payload?.Lon;

        var data = {
          lon: lon,
          lat: lat,
          tmapPrivateEvYn: "",
          evPublicType: "",
          chargerSpeed: "",
          evChargeType: "",
          operatorId: "",
          evChargeStatus: "",
          radius: "1000"
        };

        postPkeyMomenty(data, pkey)
          .then(function (response: any) {
            const result = response.body.docs;

            if (serviceId === 'destinationcall') {
              store.dispatch(appActions.setStationInfo(result[0]));
              props.history.replace("/destinationCall");
            }
          })
          .catch(function (error: any) {
            if (Const.DBG_OK) {
              alert("충전소 정보 조회실패");
            }
          });
      } else if (serviceType === "evdeliveryweb") {
        if (userData.evDeliveryYn) {
          tmapInterface.getCurrentPosition(currentPosition); //현재 좌표 불러와 deliveryweb 호출
        } else {
          props.history.replace("/evDelivery");
        }
      } else if (serviceType === "chargehistory") {
        props.history.replace("/chargeHistory");
      } else {
        getUserStatus()
          .then(function (response: any) {
            if (response.result) {
              if (response.activeSessionCount >= 3) {
                activeSession = response.activeSessionCount;
              }

              if (response.chargeSessionGuid || response.authSessionGuid) {
                deepLinkRouter();
              } else if (response.unpaidGuid) {
                store.dispatch(appActions.setGuid(response.unpaidGuid));
                modal.alert(() => {
                  props.history.replace("/nonPay");
                }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");
              } else {
                deepLinkRouter();
              }
            }
          })
          .catch(function (error: any) {
            tmapInterface.onBackKeyPressed();
          })
      }
    }

    function newFunction(evPopCloseYn: string) {
      return evPopCloseYn != "Y";
    }
  }

  return (
    <>
      <div id="page">
        <div
          className="charge-map"
          style={{ position: "relative", zIndex: "5" }}
        >

          <div className="map-box">
          </div>
        </div>
      </div>
    </>
  )
}
export { MainRouter };
